import styled from "styled-components";
import { Flex } from "../../Flex";
import { RideIncoming } from "../../data/models/ride";
import { Container } from "react-bootstrap";
import { GeoCoderDisplay } from "../../Geocoder";

const RideCardWrapper = styled(Flex)`
  border: 1px solid;
  .seat-avail {
    font-size: 24px;
  }
  .seat-no-avail {
    font-size: 24px;
  }
  cursor: pointer;
  transition: all 0.4s ease;
  border-radius: 5px;

  & * {
    text-decoration: none;
    color: var(--bs-body-color);
  }
`;

interface Props {
  ride: RideIncoming;
}

export const RideCard: React.FC<Props> = ({ ride }) => {
  if (!ride) {
    return null;
  }
  const date = new Date(ride.date_time);

  return (
    <RideCardWrapper shrink={[1, 0]} className="mb-3 p-3">
      <Container>
        <Flex gap="10px">
          <GeoCoderDisplay placeId={ride.stops[0].place_id} />
          <GeoCoderDisplay placeId={ride.stops[1].place_id} />
        </Flex>
      </Container>
      <h5 className="mb-3 text-center">
        {Intl.DateTimeFormat("en-CA", {
          weekday: "short",
          day: "2-digit",
          month: "short",
        }).format(date)}
      </h5>
    </RideCardWrapper>
  );
};
