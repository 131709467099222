import { styled } from "styled-components";

export const ContentAreaScroll = styled.div<{ size?: string }>`
  margin: 0 auto;
  height: 100%;
  position: relative;
  & > div {
    padding-bottom: 100px;
  }
  ${({ theme }) => theme.minMedia.base`
    width: 100%;
    padding: 10px;
  `}
  ${({ theme }) => theme.minMedia.tablet`
    padding: 15px;
    width: 80%;
  `}
  ${({ theme, size }) => theme.minMedia.laptop`
    padding: 15px;
    width: ${size === "lg" ? "85%" : "65%"};
  `}
  ${({ theme, size }) => theme.minMedia.laptopL`
    padding: 15px;
    width: ${size === "lg" ? "85%" : "50%"};
  `}
`;
