import { Navbar, Nav, Spinner, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LogoWrapper } from "../App";
import { MobileOnlyBox, LaptopOnlyBox } from "../styles";
import { ProfileNavLink } from "./ProfileNavLink";
import { useState } from "react";
import { useSignOut } from "../data/api/user";
import { useUser } from "../services/user";
import { styled } from "styled-components";
import logo from "../assets/img/logo.png";
import { CSSTransition } from "react-transition-group";
import { RedirectModal } from "../RedirectModal";
import { ProfileThumbnail } from "./ProfileThumbnail";

export const Navigation = () => {
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [peakMenu, setPeakMenu] = useState(false);
  const { isLoading, mutate: signOut } = useSignOut();
  const [showModal, setShowModal] = useState(false);
  const { user } = useUser();

  return (
    <Navbar
      className="px-3 box-shadow"
      onToggle={() => setNavbarExpanded((expanded) => !expanded)}
      expanded={navbarExpanded}
      expand="lg"
      collapseOnSelect
    >
      <Navbar.Brand href="/">
        <LogoWrapper>
          <Image src={logo} />
        </LogoWrapper>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <SlideMenuMobile
        onMouseEnter={() => setPeakMenu(true)}
        peakMenu={peakMenu}
        className="p-4 align-items-start box-shadow"
        id="navbarScroll"
      >
        <Nav
          onSelect={() => setPeakMenu(false)}
          style={{ maxHeight: "1000px", zIndex: 10 }}
          navbarScroll
        >
          <Nav.Item className="mb-5">
            {isLoading ? <Spinner /> : <ProfileNavLink />}
          </Nav.Item>
          <MobileOnlyBox>
            <Nav.Link
              eventKey={1}
              as={Link}
              className="p-2 mt-2 mb-2"
              to={"/user/rides"}
            >
              My rides
            </Nav.Link>
            <Nav.Link
              eventKey={2}
              as={Link}
              className="p-2 mt-2 mb-2"
              to={"rides/search"}
            >
              Find a ride
            </Nav.Link>
            <Nav.Link
              eventKey={3}
              as={Link}
              className="p-2 mt-2 mb-2"
              to={"user/rides/new"}
              onClick={() => {
                if (!user || !user.user_metadata.name) {
                  setShowModal(true);
                }
              }}
            >
              {showModal && (
                <RedirectModal
                  navigateTo={!user ? "/login" : "/profile"}
                  show={showModal}
                  returnPath={"/user/rides/new"}
                  closeModal={() => setShowModal(false)}
                />
              )}
              Publish a ride
            </Nav.Link>
            <hr />
            {!user ? (
              <>
                <Nav.Link
                  as={Link}
                  className="p-2 mt-2 mb-2"
                  eventKey={"login"}
                  to="login"
                >
                  Sign in
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  className="p-2 mt-2 mb-2"
                  eventKey={"register"}
                  to="signup"
                >
                  Sign up
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link
                  eventKey={0}
                  className="p-2 mt-2 mb-2"
                  as={Link}
                  to={"/profile"}
                >
                  Profile
                </Nav.Link>
                <Nav.Link
                  onClick={() => {
                    signOut();
                  }}
                  eventKey={3}
                  as={Button}
                  disabled={isLoading}
                  className="p-2 mt-2 mb-2"
                  to={"user/requests"}
                >
                  Sign out
                </Nav.Link>
              </>
            )}
          </MobileOnlyBox>
          <LaptopOnlyBox className="mt-5">
            <Nav.Link
              eventKey={1}
              as={Link}
              className="p-2 mt-2 mb-2"
              to={"/user/rides"}
            >
              My rides
            </Nav.Link>

            <Nav.Link
              eventKey={2}
              as={Link}
              className="p-2 mt-2 mb-2"
              to={"user/requests"}
            >
              Requests
            </Nav.Link>
            <hr />
            {!user ? (
              <>
                <Nav.Link as={Link} eventKey={"login"} to="login">
                  Sign in
                </Nav.Link>
                <Nav.Link as={Link} eventKey={"register"} to="signup">
                  Sign up
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link eventKey={0} as={Link} to={"/profile"}>
                  Profile
                </Nav.Link>
                <Nav.Link
                  onClick={() => {
                    signOut();
                  }}
                  eventKey={3}
                  as={Button}
                  disabled={isLoading}
                  className="p-2 mt-2 mb-2"
                  to={"user/requests"}
                >
                  Sign out
                </Nav.Link>
              </>
            )}
          </LaptopOnlyBox>
        </Nav>
      </SlideMenuMobile>
      <CSSTransition
        key={String(navbarExpanded)}
        timeout={300}
        classNames="fade"
      >
        {navbarExpanded ? (
          <BackgroundClickArea
            onClick={() => {
              setNavbarExpanded(false);
              setPeakMenu(false);
            }}
          />
        ) : (
          <></>
        )}
      </CSSTransition>
      <LaptopOnlyBox className="w-100">
        <div className="d-flex align-items-center justify-content-between w-100">
          <Nav>
            <Nav.Link eventKey={1} as={Link} className="p-2 mt-2 mb-2" to={"/"}>
              Home
            </Nav.Link>
            <Nav.Link
              eventKey={2}
              as={Link}
              className="p-2 mt-2 mb-2"
              to={"rides/search"}
            >
              Find a ride
            </Nav.Link>
            <Nav.Link
              eventKey={3}
              as={Link}
              className="p-2 mt-2 mb-2"
              onClick={() => {
                if (!user || !user.user_metadata.name) {
                  setShowModal(true);
                }
              }}
              to={"user/rides/new"}
            >
              {showModal && (
                <RedirectModal
                  navigateTo={!user ? "/login" : "/profile"}
                  show={showModal}
                  returnPath={"/user/rides/new"}
                  closeModal={() => setShowModal(false)}
                />
              )}
              Publish a ride
            </Nav.Link>
          </Nav>
          <Nav.Item onClick={() => setNavbarExpanded(true)}>
            <div
              className={`cursor-pointer profile-thumbnail ${
                peakMenu ? "peak" : ""
              }`}
              onMouseLeave={() => setPeakMenu(false)}
              onMouseEnter={() => setPeakMenu(true)}
            >
              {isLoading ? <Spinner /> : <ProfileThumbnail />}
            </div>
          </Nav.Item>
        </div>
      </LaptopOnlyBox>
    </Navbar>
  );
};

const SlideMenuMobile = styled(Navbar.Collapse)<{ peakMenu: boolean }>`
  background-color: rgba(255, 255, 255, 0.9);
  position: relative;
  z-index: 100;
  height: 100vh !important;

  &.navbar-collapse {
    left: -100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  /* Custom styles for the .navbar-collapse sliding from left */
  &.navbar-collapse.collapsing {
    left: -100%;
    transition: left 0.3s ease;
  }

  &.navbar-collapse.show {
    left: 0%;
    transition: left 0.3s ease;
  }

  /* Adjust the .navbar-toggler position if needed */
  .navbar-toggler {
    z-index: 1020;
  }

  ${({ theme }) => theme.minMedia.mobileS`
    &.navbar-collapse {
      width: 80vw;
    }
    `};

  ${({ theme }) => theme.minMedia.tablet`
    &.navbar-collapse {
      width: 40vw;
    }
    &.navbar-collapse.collapsing {
    // right: -100%;
    left: auto;
    height: 100vh;
    transition: right 0.3s ease;
  }

  &.navbar-collapse {
    right: -100%;
    position:fixed;
    top:0;
    left: auto;
    z-index: 1000;
    transition: right 0.3s ease;
  }
  &.navbar-collapse.show {
    left: auto;
    right: 0%;
    transition: right 0.3s ease;
  }`}
  ${({ theme }) => theme.minMedia.laptop`
    &.navbar-collapse {
      width: 30vw;
    }
  `}
`;

const BackgroundClickArea = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 10;
`;
