import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "react-query";

export const useGetPlaces = (props: UseQueryOptions & { placeId?: string }) => {
  return useQuery<any, any, any>(
    `${props.placeId}requests`,
    () =>
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${props.placeId}.json?access_token=${process.env.REACT_APP_GEOCODER_API_KEY}`
      )
        .then((res) => res.json())
        .then((data) => data.features[0]),
    props
  );
};

export const useSearchPlaces = (props: UseMutationOptions) => {
  return useMutation<{ features: MapboxGeocoder.Result[] }, any, any>(
    `requests`,
    ({ value }) => {
      const url = new URL(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json`
      );
      url.searchParams.set(
        "access_token",
        process.env.REACT_APP_GEOCODER_API_KEY || ""
      );
      url.searchParams.set("country", "ca");
      return fetch(url)
        .then((res) => res.json())
        .then((data) => data);
    },
    props
  );
};
