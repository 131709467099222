import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router";

interface Props {
  show?: boolean;
  navigateTo: string;
  closeModal?: () => void;
  returnPath?: string;
  content?: string;
}

export const RedirectModal: React.FC<Props> = ({
  show = true,
  closeModal,
  returnPath,
  navigateTo,
  content,
}) => {
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((count) => {
        return count + 1;
      });
    }, 1000);

    const timeoutId = setTimeout(() => {
      closeModal && closeModal();
      navigate(navigateTo, { replace: true, state: returnPath });
    }, 4000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [navigateTo, returnPath]);

  return (
    <Modal show={show}>
      <Modal.Header>
        <h3>Redirecting in {count}s</h3>
      </Modal.Header>
      <Modal.Body>{content && <h4>{content}</h4>}</Modal.Body>
    </Modal>
  );
};
