import { useNavigate, useParams } from "react-router";
import { useGetUserRide, useUpdateRide } from "../../data/api/ride";
import { useEffect } from "react";
import { RideForm } from "../../Forms/RideForms";

export function EditRide() {
  const { id } = useParams();
  const { data: ride } = useGetUserRide({ id, enabled: !!id });
  const { mutate: updateRide, isSuccess, isLoading, data } = useUpdateRide();
  const navigate = useNavigate();

  useEffect(() => {
    isSuccess && navigate(`/user/rides/${data.id}`);
  }, [isSuccess]);

  if (!ride) {
    return null;
  }

  return (
    <RideForm
      onSave={updateRide}
      initialRide={ride}
      savingDetails={{
        isSaved: isSuccess,
        isSaving: isLoading,
      }}
    />
  );
}
