import { Stop } from "./stop";

export enum StopUpdateAction {
  added = "added",
  updated = "updated",
  removed = "removed",
  unchanged = "unchanged",
}

export interface RideType {
  ride_id: string;
  driver_id: string;
  total_seats: number; // Total number of seats available
  stops: RideStop[];
  price_per_seat: number;
  date_time: string;
}
export interface RideIncoming extends RideType {
  id: string;
  stops: Omit<RideStop, "lat" | "lng">[];
}
export type EnrichedRide = {
  id: string;
  date_time: string;
  driver_id: string;
  users: { picture: string; full_name: string; id: string };
  stops: {
    sequence: number;
    id: string;
    place_id: string;
    lat: number;
    lng: number;
  }[];
  total_seats: number;
  avail_seats: number;
  price_per_seat: number;
};

export type SearchResultRide = {
  ride_id: string;
  stop_from_id: string;
  stop_from_place_id: string;
  stop_to_id: string;
  stop_to_place_id: string;
  driver_name: string;
  driver_avatar: string;
  stop_from_sequence: number;
  stop_to_sequence: number;
  date_time: string;
  total_seats: number;
  avail_seats: number;
  driver_id: string;
  price_per_seat: number;
};

export interface RideStop extends Omit<Stop, "lat" | "lng"> {
  id: string;
  ride_id: string;
}

export interface RideOutgoing extends Omit<RideType, "stops"> {
  id: string;
  stops: (StopAdded | StopUpdated | StopRemoved | StopUnchanged)[];
}

export interface StopRemoved extends RideStop {
  action: StopUpdateAction.removed;
  id: string;
}

export interface StopUnchanged extends RideStop {
  action: StopUpdateAction.unchanged;
  id: string;
}

export interface StopUpdated extends RideStop {
  action: StopUpdateAction.updated;
  id: string;
}

export interface StopAdded extends Omit<Partial<RideStop>, "id"> {
  action: StopUpdateAction.added;
}

export function isNewStop(stop: any): stop is StopAdded {
  return (!stop.id && !stop.action) || stop.action === StopUpdateAction.added;
}

export function isUpdatedStop(stop: any): stop is StopUpdated {
  return !!stop.id && stop.action === StopUpdateAction.updated;
}

export function isRemovedStop(stop: any): stop is StopRemoved {
  return stop.id && stop.action === StopUpdateAction.removed;
}

export function isUnchangedStop(stop: any): stop is StopUnchanged {
  return !!stop.id && stop.action === StopUpdateAction.unchanged;
}
