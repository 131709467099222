export enum RequestStatus {
  Approved = "APPROVED",
  Cancelled = "CANCELLED",
  Pending = "PENDING",
  Removed = "REMOVED",
}

export interface NewRequest {
  stop_id_from: string;
  stop_id_to: string;
  requester_id: string;
  requester_email: string;
  status: RequestStatus.Pending;
  ride_id: string;
}

export interface RideRequest {
  id: string;
  request_id: string;
  status: RequestStatus;
  ride_id: string;
  stop_id_from: string;
  stop_id_to: string;
  requester_id: string;
  requester_email: string;
  users_request: {
    picture: string;
    full_name: string;
    request_id: string;
    requester_id: string;
  }[];
  from_place_id: string;
  to_place_id: string;
  avail_seats: number;
  driver_id: string;
}
