import { Button, Image } from "react-bootstrap";
import { styled } from "styled-components";
import { Flex } from "../../Flex";
import { useEffect, useRef, useState } from "react";
import { GeoCoderInput as Input } from "../../Geocoder/GeoCoderInput";
import { useFindRides } from "../../data/api/ride";
import { Link } from "react-router-dom";
import { Bbox } from "../../services/rides";
import { DateTimePicker } from "../../DateTimePicker/DateTimePicker";
import { MobileOnlyBox } from "../../styles";
import NotFound from "../../assets/img/undraw_by_the_road_re_vvs7.svg";
import { Spinner } from "../../Spinner/Spinner";
import { ContentArea } from "../../Layout";
import { SearchRide } from "../Rides/SearchRide";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "rsuite/DatePicker/styles/index.css";
import { ErrorText } from "../../ErrorText";
import { ParamNames, useRideParams } from "../../utils/hooks";

export const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const SearchBox = styled(Flex)<{ collapse: boolean }>`
  border: 1px solid var(--primary-brand-color);
  transition: all 0.3s ease;

  position: sticky;
  flex-wrap: wrap;
  top: 20px;
  width: 100%;
  background: white;
  max-height: 450px;
  z-index: 1;
  input {
    font-size: 1.3rem;
  }
  & button {
    align-self: stretch;
  }
  ${({ theme }) => theme.maxMedia.laptop`
    
  &.collapsed {
    max-height: 95px;
    padding: 5px !important;
    padding-left: 10px;
    top: 3px;
    & > div:not(.summary) {
      display: none;
    }
  }
  .summary h6 {
    margin: 0;
    padding-left: 10px;
  }
  `}
  ${({ theme }) => theme.minMedia.base`
    
    flex-direction: column;
    & > * {
      width: 100%;
    }
  `}
  ${({ theme }) => theme.minMedia.tablet`
    flex-direction: column;
    & > button {
      flex-grow: 1;
    }
  `}
  ${({ theme }) => theme.minMedia.laptop`
    flex-direction: row;
    & > div {
      flex: 1;
    }
    & > button {
      width: 100%
    }
  `}
  ${({ theme }) => theme.minMedia.laptopM`
  flex-direction: row;
  & > div {
    flex: 1;
  }
  & > button {
    width: 100%
  }
  & .control-box{
    height: 43px;
  }
  `}
  ${({ theme }) => theme.minMedia.laptopL`
    flex-direction: row;
    & > * {
      flex: 1;
    }
  `}
`;

const ResultBox = styled(Flex)`
  transition: all 0.3s ease;
  & > div:hover {
    border: ${({ theme }) => theme.colors.primary};
    box-shadow: var(--bs-box-shadow-sm);
  }
`;

type ParamState = {
  bbox: Bbox | null;
  placeId: MapboxGeocoder.Result["id"];
  name: string;
};

const getDefaultParamState = (): ParamState => ({
  bbox: null,
  placeId: undefined,
  name: "",
});

export const Search = () => {
  const { date, endStop, setParams, startStop, URLParams } = useRideParams();

  const [showSummary, setShowSummray] = useState(
    startStop && endStop ? true : false
  );

  const [searchParams, setSearchParams] = useState<{
    startStop: ParamState;
    endStop: ParamState;
    dateTime: Date | null;
  }>({
    dateTime: null,
    endStop: getDefaultParamState(),
    startStop: getDefaultParamState(),
  });

  const [errors, setErrors] = useState<{
    from?: string;
    to?: string;
    date?: string;
  }>({});

  useEffect(() => {
    setSearchParams((params) => ({
      ...params,
      startStop: { ...params.startStop, placeId: startStop || undefined },
      endStop: { ...params.endStop, placeId: endStop || undefined },
    }));
  }, [startStop, endStop]);

  useEffect(() => {
    date && setSearchParams((prev) => ({ ...prev, dateTime: new Date(date) }));
  }, [date]);

  const ref = useRef({ isInitialLoad: startStop && endStop ? true : false });

  useEffect(() => {
    if (
      ref.current.isInitialLoad &&
      searchParams.startStop.bbox &&
      searchParams.endStop.bbox &&
      searchParams.startStop.placeId &&
      searchParams.endStop.placeId
    ) {
      handleFindRides(
        searchParams.startStop.bbox,
        searchParams.endStop.bbox,
        searchParams.dateTime
      );
      ref.current.isInitialLoad = false;
    }
  }, [ref.current.isInitialLoad, searchParams.endStop, searchParams.startStop]);

  const {
    mutate: findRides,
    data: rides,
    isLoading,
    isSuccess,
    error,
    reset,
  } = useFindRides();

  useEffect(() => {
    setErrors(error || {});
  }, [error]);

  const handleFindRides = (from: Bbox, to: Bbox, date: Date | null) => {
    reset();
    const aDayBefore = date ? new Date(date.toISOString()) : undefined;
    aDayBefore?.setDate(aDayBefore.getDate() - 1);
    findRides({ from: from, to: to, date: aDayBefore?.toISOString() });
  };

  const handleLocationSelect = (type, result: any) => {
    if (result.id) {
      result.id && URLParams.set(type, result.id.toString());
      setParams(URLParams);
    }
    setSearchParams((params) => ({
      ...params,
      [type]: {
        bbox: result.bbox,
        name: result.name,
        placeId: result.id,
      } as ParamState,
    }));
  };

  useEffect(() => {
    if (isSuccess) {
      rides?.length > 0 && setShowSummray(true);
    }
  }, [isSuccess]);

  return (
    <MapContainer>
      <ContentArea size="lg">
        <SearchBox
          collapse={showSummary}
          className={`p-4 rounded mb-3 box-shadow ${
            showSummary ? "collapsed" : ""
          }`}
          gap="25px"
          alignItems="flex-start"
        >
          <>
            <div>
              <Input
                isInvalid={!!errors?.from}
                reverseSearchValue={searchParams.startStop.placeId?.toString()}
                onClear={() => {
                  URLParams.delete("startStop");
                  setParams(URLParams);
                  setSearchParams((params) => ({
                    ...params,
                    startStop: getDefaultParamState(),
                  }));
                }}
                onSelect={(result) => {
                  setErrors(({ from, ...rest }) => rest);
                  handleLocationSelect(ParamNames.startStop, result);
                }}
                inputProps={{ placeholder: "Leaving from" }}
              />
              {errors?.from && <ErrorText>Specify location</ErrorText>}
            </div>
            <div>
              <Input
                isInvalid={!!errors?.to}
                reverseSearchValue={searchParams.endStop.placeId?.toString()}
                onSelect={(result) => {
                  setErrors(({ to, ...rest }) => rest);
                  handleLocationSelect(ParamNames.endStop, result);
                }}
                onClear={() => {
                  URLParams.delete("endStop");
                  setParams(URLParams);
                  setSearchParams((params) => ({
                    ...params,
                    endStop: getDefaultParamState(),
                  }));
                }}
                inputProps={{ placeholder: "Going to" }}
              />
              {errors?.to && <ErrorText>Specify location</ErrorText>}
            </div>
            <div>
              <DateTimePicker
                oneTap
                isInvalid={!!errors?.date}
                onClean={() => {
                  URLParams.delete("date");
                  setParams(URLParams);
                  setSearchParams((params) => ({ ...params, dateTime: null }));
                }}
                classPrefix="poolit"
                onSelect={(date) => {
                  URLParams.set("date", date.toISOString());
                  setParams(URLParams);
                  setErrors(({ date, ...rest }) => rest);
                  setSearchParams((prev) => ({
                    ...prev,
                    dateTime: date,
                  }));
                }}
                editable
                format="MMM dd, yyyy"
                value={
                  searchParams.dateTime ? new Date(searchParams.dateTime) : null
                }
                showMeridian
              />

              {errors?.date && <ErrorText>Specify date</ErrorText>}
            </div>
            <Flex
              className="control-box"
              alignItems="center"
              gap="25px"
              grow={[3, 0]}
            >
              <Button
                disabled={!!Object.keys(errors).length}
                onClick={() => {
                  handleFindRides(
                    searchParams.startStop.bbox as Bbox,
                    searchParams.endStop.bbox as Bbox,
                    searchParams.dateTime
                  );
                }}
              >
                Search
              </Button>
              <MobileOnlyBox>
                <Button onClick={() => setShowSummray(true)}>
                  <i className="bi bi-chevron-up"></i>
                </Button>
              </MobileOnlyBox>
            </Flex>
          </>
          {showSummary && (
            <MobileOnlyBox className="summary">
              <Flex
                gap="10px"
                grow={[1, 1, 1, 0]}
                alignItems="center"
                justifyContent="space-between"
              >
                {!searchParams.startStop.name &&
                  !searchParams.endStop.name &&
                  !searchParams.dateTime && <h6>Nothing is selected</h6>}
                <h6>{searchParams.startStop.name}</h6>
                <h6>{searchParams.endStop.name} </h6>
                <h6 className="text-align-right">
                  {searchParams.dateTime &&
                    Intl.DateTimeFormat("en-CA", {
                      weekday: "short",
                      day: "2-digit",
                      month: "short",
                    }).format(searchParams.dateTime)}
                </h6>
                <Button onClick={() => setShowSummray(false)}>
                  <i className="bi bi-pencil-fill"></i>
                </Button>
              </Flex>
            </MobileOnlyBox>
          )}
        </SearchBox>
        <ContentArea>
          {rides && rides.length === 0 && (
            <Flex
              gap="25px"
              className="mt-5"
              direction="column"
              alignItems="center"
            >
              <h5 className="text-center">
                Sorry, there are currently no trips from{" "}
                {searchParams.startStop.name} to {searchParams.endStop.name} on{" "}
                {searchParams.dateTime &&
                  Intl.DateTimeFormat("en-CA", {
                    weekday: "long",
                    day: "2-digit",
                    month: "short",
                  }).format(searchParams.dateTime)}
              </h5>
              <Image rounded src={NotFound} width={"100%"} />
            </Flex>
          )}
          <ResultBox gap="10px" direction="column" className="pt-4 pb-4">
            {isLoading && <Spinner type="modal" />}
            {rides?.map((ride) => {
              return (
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/user/rides/${ride.ride_id}?startStop=${ride.stop_from_id}&endStop=${ride.stop_to_id}`}
                >
                  <SearchRide ride={ride} />
                </Link>
              );
            })}
          </ResultBox>
        </ContentArea>
      </ContentArea>
    </MapContainer>
  );
};
