import React from "react";
import { useGetPlaces } from "../data/api/places";

export interface Props {
  placeId: string | undefined;
}

export const GeoCoderDisplay: React.FC<Props> = ({ placeId }) => {
  const { data } = useGetPlaces({ placeId });

  return <h5 className="m-0 stop-display-place">{data?.place_name}</h5>;
};
