import {
  QueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { NewRequest, RequestStatus, RideRequest } from "../models/request";
import {
  createRequest,
  readRequest,
  readRequests,
  ReadRequestsArgs,
  updateRequest,
} from "../../services/requests";

type GetRideRequests = QueryOptions & {
  status: RequestStatus;
  ride_id: string | undefined;
};
type UpdateRequests = MutationOptions & {
  ride_id: string | undefined;
};

export type MutationOptions = UseMutationOptions<any, any, NewRequest, any>;
export const useCreateRequest = (props?: MutationOptions) => {
  return useMutation<any, any, NewRequest>(
    "createRequest",
    (request) => createRequest(request),
    props
  );
};

export const useGetRideRequests = (props: GetRideRequests) => {
  return useQuery<any, any, { data: RideRequest[] }>(
    `${props.ride_id}requests`,
    () => {
      if (!props.ride_id) {
        return;
      }
      return readRequests(props.ride_id, props.status);
    },
    props
  );
};
type Props = UseQueryOptions & ReadRequestsArgs;
export const useGetRequest = (props: Props) => {
  const { requester_id, ride_id, stop_id_from, stop_id_to } = props;
  return useQuery(
    "getRequest",
    () =>
      readRequest({
        requester_id,
        stop_id_from,
        stop_id_to,
        ride_id,
      }),
    { enabled: props.enabled }
  );
};

export const useUpdateRequest = (props: UpdateRequests) => {
  const queryClient = useQueryClient();
  return useMutation<any, any, Partial<RideRequest>>(
    `updateRequest`,
    (request) => updateRequest(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`${props.ride_id}requests`);
        queryClient.resetQueries("updateRequest");
      },
    }
  );
};
