import { Button, Modal, ModalProps } from "react-bootstrap";

interface Props extends ModalProps {
  onCancel: () => void;
}

export const RequestCancelModal: React.FC<Props> = ({
  show,
  onCancel,
  onHide,
}) => {
  return (
    <Modal centered show={show}>
      <Modal.Header>
        <h4>Cancel request</h4>
      </Modal.Header>
      <Modal.Body>
        <h5>
          Cancelling approved request will affect your review as a driver.
        </h5>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onCancel}>
          Cancel request
        </Button>
        <Button variant="success" onClick={onHide}>
          Go back
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
