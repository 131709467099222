import React, { useEffect, useState } from "react";
import {
  Alert as AlertBootstrap,
  Button,
  Container,
  Form,
  Image,
  Modal,
} from "react-bootstrap";
import { useUser } from "../../services/user";
import { Flex } from "../../Flex";
import { useResendConfirmation, useUpdateUser } from "../../data/api/user";
import { User } from "@supabase/supabase-js";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { placeholderAvatar } from "../../constants";
import { Spinner } from "../../Spinner/Spinner";
import { ContentArea } from "../../Layout";

const StyledButtonWrap = styled.div`
  & * {
    font-size: 1.2rem;
  }
  & button {
    color: var(--primary-brand-color);
    transition: 0.3s ease;
  }
  & .bi-check-circle-fill {
    color: var(--primary-brand-color);
  }
`;
const LinkH5 = styled.h5`
  color: var(--primary-brand-color);
  text-decoration: underline;
`;
export const Alert = styled(AlertBootstrap)`
  background: transparent;
  a {
    color: var(--primary-brand-color);
  }
`;

export const Profile: React.FC = () => {
  const { user, isLoading: isUserLoading } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const notAuthenticated = !isUserLoading && !user;
  const needsAction = !notAuthenticated && !user?.user_metadata.name?.trim();
  const needEmailConfirmation =
    !notAuthenticated && user?.email_confirmed_at === null;
  const [userForm, setUserForm] = useState<User["user_metadata"]>({
    firstName: "",
    lastName: "",
  });
  const { mutate: updateUser, reset, isLoading, isSuccess } = useUpdateUser();
  useEffect(() => {
    isSuccess && navigate(location.state);
  }, [isSuccess]);
  const { mutate: resendConfirmation, isLoading: isResendLodaing } =
    useResendConfirmation();

  const [showModal, setShowModal] = useState(false);

  const submitUserForm = () => {
    updateUser({ name: `${userForm.firstName} ${userForm.lastName}` });
  };

  useEffect(() => {
    isSuccess &&
      setTimeout(() => {
        setShowModal(false);
        reset();
      }, 500);
  }, [isSuccess]);

  return (
    <ContentArea>
      <Container>
        <Modal
          onHide={() => setShowModal(false)}
          onBackdropClick={() => setShowModal(false)}
          show={showModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>First name</Form.Label>
                <Form.Control
                  onChange={(event) => {
                    setUserForm((data) => ({
                      ...data,
                      firstName: event.target.value,
                    }));
                  }}
                  disabled={isLoading}
                  value={userForm.firstName}
                  placeholder=""
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  onChange={(event) => {
                    setUserForm((data) => ({
                      ...data,
                      lastName: event.target.value,
                    }));
                  }}
                  disabled={isLoading}
                  value={userForm.lastName}
                  placeholder=""
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Form.Group>
              <StyledButtonWrap>
                <Button
                  variant="outline-success"
                  disabled={isLoading || isSuccess}
                  onClick={() => submitUserForm()}
                >
                  <Flex alignItems="center" gap="10px">
                    {isSuccess ? "Saved" : "Save"}
                    {isSuccess && <i className="bi bi-check-circle-fill"></i>}
                    {isLoading && <Spinner size="sm" type="inline" />}
                  </Flex>
                </Button>
              </StyledButtonWrap>
            </Form.Group>
          </Modal.Footer>
        </Modal>
        <Flex gap="20px" grow={[0, 1, 1, 1]} direction="column">
          <h1>Profile</h1>
          <Flex alignItems="center" gap="50px">
            <Image
              width={"100px"}
              roundedCircle
              src={user?.user_metadata.picture || placeholderAvatar}
            />
            <h3>{user?.user_metadata.name}</h3>
          </Flex>
          {needsAction && (
            <Alert variant={"danger"}>
              <Alert.Heading>Missing user details</Alert.Heading>
              <Flex justifyContent="space-between" alignItems="center">
                <div>You need to provide your first nd last name.</div>
                <Alert.Link as={Button} onClick={() => setShowModal(true)}>
                  Update
                </Alert.Link>
              </Flex>
            </Alert>
          )}
          <h5>{user?.email}</h5>
          {needEmailConfirmation && (
            <Alert variant={"danger"}>
              <Alert.Heading>Email not confirmed</Alert.Heading>
              <Flex justifyContent="space-between" alignItems="center">
                <div>
                  You need to confirm your email. See your inbox at{" "}
                  {user?.email}
                </div>
                <Alert.Link
                  as={Button}
                  onClick={() => user?.email && resendConfirmation(user.email)}
                >
                  Resend {isResendLodaing && <Spinner size="sm" />}
                </Alert.Link>
              </Flex>
            </Alert>
          )}
        </Flex>
        {isUserLoading && <Spinner />}
        {notAuthenticated && (
          <Alert variant="info">
            <Flex>
              <Alert.Heading>You are not logged in</Alert.Heading>
            </Flex>
            <Link to={"/login"}>
              <LinkH5>Go to Login</LinkH5>
            </Link>
          </Alert>
        )}
      </Container>
    </ContentArea>
  );
};
