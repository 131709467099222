import { Link } from "react-router-dom";
import { useGetUserRides } from "../../data/api/ride";
import { useUser } from "../../services/user";
import { RideCard } from "./RideCard";
import { Spinner } from "../../Spinner/Spinner";
import { styled } from "styled-components";

const LinkStyled = styled(Link)`
  & {
    text-decoration: none;
    & > div:hover {
      border-color: ${({ theme }) => theme.colors.primary};
      box-shadow: var(--bs-box-shadow-sm);
    }
  }
`;

export function RideList() {
  const { user } = useUser();

  const { data, isFetching } = useGetUserRides({
    id: user?.id,
    enabled: !!user?.id,
  });

  return (
    <>
      <h3>Upcoming rides</h3>
      {isFetching && <Spinner type="modal" variant="themed" />}
      {data?.map((ride) => {
        return (
          <LinkStyled to={`/user/rides/${ride.id}`}>
            <RideCard key={ride.id} ride={ride} />;
          </LinkStyled>
        );
      })}
    </>
  );
}
