import { Button } from "react-bootstrap";
import { RideIncoming, RideOutgoing, isRemovedStop } from "../data/models/ride";
import { DetailsRow, Type } from "../Pages/Rides/DetailsRow";
import { Flex } from "../Flex";
import MapboxGeocoder, { Result } from "@mapbox/mapbox-gl-geocoder";
import { styled } from "styled-components";
import { GeoCoderInput } from "../Geocoder/GeoCoderInput";

interface Props {
  ride: RideIncoming | RideOutgoing;
  errors: any;
  onPlaceChange: (
    index: number,
    value: {
      name: Result["text"];
      lat: number;
      lng: number;
      place_id: string;
    }
  ) => void;
  onPlaceClear: (index: number) => void;
  onAddStop?: () => void;
  onRemoveStop: (index: number) => void;
}
const ButtonWrapper = styled.div`
  & button {
    border: 1px solid;
    border-radius: 5px;
    height: 42px;
  }
`;
export const StopsDisplayEdit: React.FC<Props> = ({
  ride,
  onPlaceChange,
  onAddStop,
  onRemoveStop,
  onPlaceClear,
  errors,
}) => {
  const onSelect = (
    index: number,
    selection: {
      lat: number;
      lng: number;
      name: string;
      id: MapboxGeocoder.Result["id"];
    }
  ) => {
    const { lat, lng, name, id } = selection;
    onPlaceChange(index, {
      lat,
      lng,
      name,
      place_id: id as string,
    });
  };

  return (
    <>
      {ride.stops.map((stop, index) => {
        if (isRemovedStop(stop)) {
          return null;
        }
        if (index === 0) {
          return (
            <>
              <DetailsRow<Type.origin>
                type={Type.origin}
                place={
                  <GeoCoderInput
                    isInvalid={!!errors?.stops?.[index]}
                    reverseSearchValue={stop.place_id}
                    onClear={() => onPlaceClear(index)}
                    onSelect={(selection) => onSelect(index, selection)}
                    inputProps={{ placeholder: "Leaving from" }}
                  />
                }
                time={null}
              />
              <DetailsRow<Type.dot> type={Type.dot} />
            </>
          );
        }
        if (index === ride.stops.length - 1) {
          return (
            <>
              {/* <DetailsRow<Type.stopover>
                type={Type.stopover}
                place={
                  <ButtonWrapper>
                    <Button
                      className="w-100"
                      variant="outline-info"
                      onClick={onAddStop}
                    >
                      Add new stop
                    </Button>
                  </ButtonWrapper>
                }
                time={null}
              /> */}
              <DetailsRow<Type.dot> type={Type.dot} />
              <DetailsRow<Type.destination>
                type={Type.destination}
                place={
                  <GeoCoderInput
                    isInvalid={!!errors?.stops?.[index]}
                    reverseSearchValue={stop.place_id}
                    onSelect={(selection) => onSelect(index, selection)}
                    onClear={() => onPlaceClear(index)}
                    inputProps={{ placeholder: "Going to" }}
                  />
                }
                time={null}
              />
            </>
          );
        }
        return (
          <>
            <DetailsRow<Type.stopover>
              type={Type.stopover}
              place={
                <Flex grow={[3, 1]} alignItems="center" gap="10px">
                  <GeoCoderInput
                    isInvalid={!!errors?.stops?.[index]}
                    reverseSearchValue={stop.place_id}
                    onSelect={(selection) => onSelect(index, selection)}
                    onClear={() => onPlaceClear(index)}
                    inputProps={{ placeholder: "Stopping at" }}
                  />
                  <ButtonWrapper>
                    <Button
                      className="w-100"
                      variant="outline-danger"
                      onClick={() => onRemoveStop(index)}
                    >
                      Remove
                    </Button>
                  </ButtonWrapper>
                </Flex>
              }
              time={null}
            />
            <DetailsRow<Type.dot> type={Type.dot} />
          </>
        );
      })}
    </>
  );
};
