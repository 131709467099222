import styled from "styled-components";
import { Auth } from "@supabase/auth-ui-react";
import { supabase } from "../../services/supabase";
import { useLocation } from "react-router";
import { ContentArea } from "../../Layout";
import { ViewType } from "@supabase/auth-ui-shared";

const Container = styled.div`
  .primary-button {
    border: 1px solid #78c2ad;
    padding: 5px;
    border-radius: 5px;
    transition: 0.3s ease;
    &:hover {
      background-color: #78c2ad;
      color: white;
    }
    &[type="submit"] {
      width: 96%;
      margin: 0 auto;
    }
  }
  .input-field {
    border: 1px solid #78c2ad;
    padding: 10px;
    border-radius: 5px;
  }
  width: 80%;
`;

export const AuthPage = ({ view }: { view?: ViewType }) => {
  const { state } = useLocation();

  return (
    <ContentArea>
      <Container className="p-4">
        <Auth
          redirectTo={window.location.origin + state || ""}
          providers={["google", "facebook"]}
          appearance={{
            className: {
              button: "primary-button",
              input: "input-field",
              container: "container",
            },
          }}
          view={view}
          supabaseClient={supabase}
        />
      </Container>
    </ContentArea>
  );
};
