import React from "react";
import { Image, Spinner } from "react-bootstrap";
import { Flex } from "../../Flex";
import { useGetUser } from "../../data/api/user";
import { useParams } from "react-router";
import { ContentArea } from "../../Layout";

export const User: React.FC = () => {
  const { id } = useParams();
  const {
    data: user,
    isFetching,
    isLoading,
  } = useGetUser({ id, enabled: !!id });

  if (!user) return null;

  return (
    <ContentArea>
      {isFetching || (isLoading && <Spinner />)}
      <Flex gap="50px" grow={[0, 1, 1, 1]} direction="column">
        <Flex alignItems="center" gap="50px">
          <Image width={"100px"} roundedCircle src={user.picture} />
          <h3>{user.full_name}</h3>
        </Flex>
        <h3>{user.email}</h3>
      </Flex>
    </ContentArea>
  );
};
