import { Bbox } from "../../services/rides";
import Joi from "joi";

const customJoi = Joi.defaults((schema) => {
  return schema.empty("").messages({
    "string.empty": "Required", // Default message for empty strings
    "any.required": "Required", // Default message for any required validation
  });
});

const bboxSchema = Joi.object({
  min_lat: Joi.number().min(-90).max(90).required(),
  min_lng: Joi.number().min(-180).max(180).required(),
  max_lat: Joi.number().min(-90).max(90).required(),
  max_lng: Joi.number().min(-180).max(180).required(),
}).label("BboxSchema");

const paramsSchema = customJoi.object<{
  from: Bbox;
  to: Bbox;
  date: string;
}>({
  from: bboxSchema,
  to: bboxSchema,
  date: customJoi.string().required(), // There must be at least one stop
});

export const validateSearchParams = (params: {
  from?: Bbox;
  to?: Bbox;
  date?: string;
}) => {
  const { error, value } = paramsSchema.validate(params, {
    abortEarly: false,
    errors: {},
  });
  if (error) {
    const errors = error.details.reduce<Record<string, string[] | any>>(
      (acc, currentError) => {
        // currentError.path is an array, so use join to create a string path if nested
        if (acc[currentError.path[0]]) {
          acc[currentError.path[0]] = createErrorObjForPath(
            currentError,
            1,
            acc[currentError.path[0]]
          );
          return acc;
        }
        return { ...acc, ...createErrorObjForPath(currentError, 0, acc) };
      },
      {}
    );
    return { errors };
  }
  return { value };
};

function createErrorObjForPath(
  error: Joi.ValidationErrorItem,
  currentIndex: number,
  obj: Record<string, any> = {}
): Record<string, any> {
  if (error.path.length - 1 === currentIndex) {
    obj[error.path[currentIndex]] = error.message;
    return obj;
  }

  obj[error.path[currentIndex]] = createErrorObjForPath(
    error,
    currentIndex + 1,
    obj[error.path[currentIndex]]
  );
  return obj;
}
