import { Row, Col } from "react-bootstrap";
import styled from "styled-components";

export enum Type {
  origin = "origin",
  destination = "destination",
  dot = "dot",
  stopover = "stopover",
  cell = "cell",
}

type ConditionalProps<T extends Type> = T extends Type.dot
  ? {
      type: T;
      time?: React.ReactNode;
      place?: React.ReactNode;
    }
  : {
      type: T;
      time: React.ReactNode;
      place: React.ReactNode;
    };

const StyledRow = styled(Row)`
  & .bold {
    & * {
      font-weight: bold;
    }
  }
  & {
    h3 {
      margin: 0;
    }
    .stopover,
    .origin,
    .destination {
      display: flex;
      align-items: center;
      height: 100%;
    }
    ${({ theme }) => theme.minMedia.base`
        h3 {
          font-size: small;
        }
      `}

    ${({ theme }) => theme.minMedia.mobileM`
          h3 {
            font-size: medium;
          }
      `}

      ${({ theme }) => theme.minMedia.tablet`
          h3 {
            font-size: larger;
          }
      `}
  }

  & {
    .origin::before {
      content: "\\F287";
      font-family: "Bootstrap-icons";
      display: inline-block;
      text-align: center;
      width: 30px;
      font-size: 1.1rem;
      line-height: 1.1rem;
      color: ${({ theme }) => theme.colors.grey};
    }
    .dot::before {
      content: "\\F287";
      font-family: "Bootstrap-icons";
      display: inline-block;
      width: 30px;
      text-align: center;
      font-size: 0.5rem;
      color: ${({ theme }) => theme.colors.grey};
    }
    .stopover::before {
      content: "\\F28A";
      font-family: "Bootstrap-icons";
      display: inline-block;
      width: 30px;
      text-align: center;
      font-size: 1.2rem;
      line-height: 1.2rem;
      color: ${({ theme }) => theme.colors.grey};
    }
    .destination::before {
      content: "\\F3E7";
      font-family: "Bootstrap-icons";
      display: inline-block;
      width: 30px;
      text-align: center;
      font-size: 1.1rem;
      line-height: 1.1rem;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

export function DetailsRow<T extends Type>({
  type,
  place,
  time,
}: ConditionalProps<T>) {
  const showDetails =
    type &&
    [Type.destination, Type.origin, Type.stopover, Type.cell].includes(
      Type[type]
    );
  const className = Type[type];
  const isTitleBold = [Type.destination, Type.origin].includes(type);
  return (
    <StyledRow className="mb-2 w-100">
      {time && (
        <Col className="p-0" xs={2} md={3} lg={2} xl={2}>
          <h3>{showDetails && type != Type.cell && time}</h3>
        </Col>
      )}
      <Col className="p-0" xs={1} md={1} lg={1} xl={1}>
        {type != Type.cell && <span className={className} />}
      </Col>
      <Col
        className={`p-0 ${isTitleBold ? "bold" : ""}`}
        xs={time ? 9 : 11}
        md={time ? 8 : 11}
        lg={time ? 9 : 11}
        xl={time ? 9 : 11}
      >
        {showDetails && place}
      </Col>
    </StyledRow>
  );
}
