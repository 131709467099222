import { useState } from "react";
import { Form, FormControlProps } from "react-bootstrap";

interface Props extends FormControlProps {
  initialValue: string;
  regExp: RegExp;
  handleChange: (arg: { value: null | number }) => void;
}

/**
 * @param handleChange - will be called if input value matches the pattern and is not NAN. If value isNAN it will return value as null.
 * @param initialValue - will be loaded as initial value and then it will keep it is own local state for displaying to the user
 * @param pattern - input regex pattern. User won't be able to type non matching chars
 * @returns
 */

export const NumberField: React.FC<Props> = ({
  handleChange,
  initialValue,
  regExp,
  onBlur,
  ...rest
}) => {
  const [value, setValue] = useState<string>(initialValue);

  const _handleChange = (value: string) => {
    const isMatch = regExp.test(value);
    if (isMatch) {
      setValue(value);
      const parsedValue = parseFloat(value);
      handleChange({ value: isNaN(parsedValue) ? null : parsedValue });
    }
  };

  return (
    <Form.Control
      value={value}
      onChange={(event) => {
        _handleChange(event.target.value);
      }}
      onBlur={onBlur}
      {...rest}
    />
  );
};
