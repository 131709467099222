// @ts-nocheck
import Joi from "joi";
import { RideType, RideStop } from "../models/ride";

const customJoi = Joi.defaults((schema) => {
  return schema.empty("").messages({
    "string.empty": "Required", // Default message for empty strings
    "any.required": "Required", // Default message for any required validation
  });
});

// Define the schema for a single stop
const rideStopSchema = customJoi.object<RideStop>({
  lat: customJoi.number().required(),
  lng: customJoi.number().required(),
  sequence: customJoi.number().required(),
  place_id: customJoi.string().required(),
  action: customJoi.string().required(),
});

// Define the schema for the ride
const rideSchema = customJoi.object<RideType>({
  date_time: customJoi.string().required(),
  driver_id: customJoi.string().required(),
  price_per_seat: customJoi.number().precision(2).greater(0).required(),
  total_seats: customJoi.number().integer().greater(0).required(),
  stops: customJoi.array().items(rideStopSchema).min(2).required(), // There must be at least one stop
});

// Example usage:
export const validateRide = (ride: RideType) => {
  const { error, value } = rideSchema.validate(ride, {
    abortEarly: false,
    errors: {},
  });
  if (error) {
    const errors = error.details.reduce<Record<string, string[] | any>>(
      (acc, currentError) => {
        // currentError.path is an array, so use join to create a string path if nested
        if (acc[currentError.path[0]]) {
          acc[currentError.path[0]] = createErrorObjForPath(
            currentError,
            1,
            acc[currentError.path[0]]
          );
          return acc;
        }
        return { ...acc, ...createErrorObjForPath(currentError, 0, acc) };
      },
      {}
    );
    return { errors };
  }
  return { value };
};

function createErrorObjForPath(
  error: Joi.ValidationErrorItem,
  currentIndex: number,
  obj: Record<string, any> = {}
): Record<string, any> {
  if (error.path.length - 1 === currentIndex) {
    obj[error.path[currentIndex]] = error.message;
    return obj;
  }

  obj[error.path[currentIndex]] = createErrorObjForPath(
    error,
    currentIndex + 1,
    obj[error.path[currentIndex]]
  );
  return obj;
}
// For Any Type:
// "any.required"
// "any.invalid"
// "any.unknown"
// For Strings:
// "string.base"
// "string.empty"
// "string.min"
// "string.max"
// "string.length"
// "string.alphanum"
// "string.token"
// "string.regex.base"
// "string.regex.name"
// "string.regex.invert.base"
// "string.regex.invert.name"
// "string.email"
// "string.uri"
// "string.guid"
// "string.hex"
// "string.hostname"
// "string.normalize"
// "string.lowercase"
// "string.uppercase"
// "string.trim"
// "string.creditCard"
// For Numbers:
// "number.base"
// "number.min"
// "number.max"
// "number.less"
// "number.greater"
// "number.integer"
// "number.negative"
// "number.positive"
// "number.precision"
// "number.multiple"
// For Arrays:
// "array.base"
// "array.includes"
// "array.includesSingle"
// "array.includesOne"
// "array.includesOneSingle"
// "array.excludes"
// "array.excludesSingle"
// "array.min"
// "array.max"
// "array.length"
// "array.ordered"
// "array.orderedLength"
// "array.ref"
// "array.sparse"
// "array.unique"
// For Objects:
// "object.base"
// "object.child"
// "object.min"
// "object.max"
// "object.length"
// "object.allowUnknown"
// "object.with"
// "object.without"
// "object.missing"
// "object.xor"
// "object.or"
// "object.and"
// "object.nand"
// "object.assert"
// "object.rename.multiple"
// "object.rename.override"
// "object.keys"
// For Dates:
// "date.base"
// "date.format"
// "date.strict"
// "date.min"
// "date.max"
// "date.isoDate"
// "date.timestamp"
// "date.ref"
// For Booleans:
// "boolean.base"
// For Binary:
// "binary.base"
// "binary.min"
// "binary.max"
// "binary.length"
// For Alternatives:
// "alternatives.base"
// "alternatives.types"
// "alternatives.match"
// For Specialized Cases:
// "function.base"
// "function.arity"
// "function.minArity"
// "function.maxArity"
