import { Tab, TabContent, Tabs } from "react-bootstrap";
import { Requests } from "../Requests/List";
import { RequestStatus } from "../../data/models/request";

export const RequestTabs = () => {
  return (
    <Tabs
      defaultActiveKey="approved"
      id="uncontrolled-tab-example"
      as={"h4"}
      className="mb-3"
    >
      <Tab eventKey="approved" title="Approved">
        <TabContent>
          <Requests status={RequestStatus.Approved} />
        </TabContent>
      </Tab>
      <Tab eventKey="pending" title="Pending">
        <TabContent>
          <Requests status={RequestStatus.Pending} />
        </TabContent>
      </Tab>
      {/* <Tab eventKey="cancelled" title="Cancelled">
            <TabContent>
              <Requests status={RequestStatus.Cancelled} />
            </TabContent>
          </Tab>
          <Tab transition eventKey="removed" title="Removed">
            <TabContent>
              <Requests status={RequestStatus.Removed} />
            </TabContent>
          </Tab> */}
    </Tabs>
  );
};
