import React, { useState } from "react";
import { RefObject, useEffect } from "react";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

/**
 * Hook that triggers a callback when a click is detected outside of the referenced element
 * @param ref - The ref of the element to detect outside clicks for
 * @param callback - The callback function to call on an outside click
 */
export function useOutsideClick<T extends HTMLElement>(
  ref: RefObject<T>,
  callback: (event: MouseEvent) => void
): void {
  useEffect(() => {
    /**
     * Call the callback if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, callback]);
}

export function useURLQuery() {
  const { search } = useLocation();

  return React.useMemo(() => {
    return search
      .replace("?", "")
      .split("&")
      .reduce<Record<string, string>>((acc, curr) => {
        const [key, value] = curr.split("=");
        acc[key] = value;
        return acc;
      }, {});
  }, [search]);
}

export enum ParamNames {
  startStop = "startStop",
  endStop = "endStop",
  date = "date",
}

export const useRideParams = () => {
  const [URLParams, setURLParams] = useSearchParams();
  const startStop = URLParams.get(ParamNames.startStop);
  const endStop = URLParams.get(ParamNames.endStop);
  const date = URLParams.get(ParamNames.date);

  return { startStop, endStop, date, setParams: setURLParams, URLParams };
};

export const useDebouncedValue = (value, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
