import { Accordion, Badge, Button, Image, Modal } from "react-bootstrap";
import { useUpdateRequest } from "../../data/api/request";
import { RequestStatus, RideRequest } from "../../data/models/request";
import { Flex } from "../../Flex";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Alert } from "../Profile/Profile";
import { Spinner } from "../../Spinner/Spinner";
import { RequestCancelModal } from "./RequestCancelModal";
import { useUser } from "../../services/user";
import { GeoCoderDisplay } from "../../Geocoder";

interface Props {
  request: RideRequest;
  eventIndex: number;
}

export const Request: React.FC<Props> = ({ eventIndex, request }) => {
  const {
    mutate: updateRequest,
    isLoading,
    isSuccess,
  } = useUpdateRequest({ ride_id: request.ride_id });
  const { user } = useUser();
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const onRemove = () => {
    setShowCancelModal(true);
  };
  const onCancel = () => {
    setShowCancelModal(true);
  };
  const onApprove = (id: string) => {
    request.avail_seats > 0
      ? updateRequest({ status: RequestStatus.Approved, id })
      : openModal();
  };
  const Controls = ({ id, isLoading }: { id: string; isLoading: boolean }) => {
    if (request.driver_id !== user?.id) {
      return null;
    }
    const statusHanlders: Record<RequestStatus, (id: string) => void> = {
      [RequestStatus.Approved]: onRemove,
      [RequestStatus.Pending]: onCancel,
      [RequestStatus.Cancelled]: onApprove,
      [RequestStatus.Removed]: onApprove,
    };
    switch (true) {
      case request.status === RequestStatus.Approved:
        return (
          <Button
            onClick={() => {
              statusHanlders[request.status](id);
            }}
          >
            Decline
          </Button>
        );
      case request.status === RequestStatus.Pending:
        return (
          <Flex alignItems="center" gap="10px" justifyContent="flex-end">
            <Button onClick={() => onApprove(id)}>Approve</Button>
            <Button
              onClick={() => {
                onCancel();
              }}
            >
              Decline
            </Button>
          </Flex>
        );
      case request.status === RequestStatus.Removed:
        return (
          <Button
            disabled={isLoading}
            onClick={() => statusHanlders[request.status](id)}
          >
            <Flex gap="10px">
              <span>Approve</span>
              {isLoading && <Spinner size="sm" />}
            </Flex>
          </Button>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <RequestCancelModal
        show={showCancelModal}
        onHide={() => {
          setShowCancelModal(false);
        }}
        onCancel={() =>
          updateRequest({ status: RequestStatus.Cancelled, id: request.id })
        }
      />
      <Accordion.Item eventKey={eventIndex.toString()}>
        <Accordion.Header>
          <Flex fillWidth alignItems="center" justifyContent="space-between">
            <Flex gap="20px">
              <GeoCoderDisplay placeId={request.from_place_id} />
              <GeoCoderDisplay placeId={request.to_place_id} />
            </Flex>
            <div>
              <Badge
                onClick={() => openModal()}
                as={"div"}
                className="me-3"
                bg="success"
              >
                Available seats {request.avail_seats}
              </Badge>
              <Modal onBackdropClick={() => closeModal()} show={showModal}>
                <Modal.Header closeButton onHide={() => closeModal()}>
                  <Modal.Title>
                    <Flex gap="20px">
                      <GeoCoderDisplay placeId={request.from_place_id} />
                      <GeoCoderDisplay placeId={request.to_place_id} />
                    </Flex>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {request.avail_seats ? (
                    <h5>
                      There are {request.avail_seats} available seats for this
                      trip
                    </h5>
                  ) : (
                    <h5>
                      Trip is full. You cannot approve requests if there is no
                      available seats
                    </h5>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => closeModal()} size="lg">
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Flex>
        </Accordion.Header>
        <Accordion.Body>
          <div className="mb-5">
            {request.users_request.map(
              ({ full_name, picture, request_id, requester_id }) => {
                return (
                  <Alert>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Flex
                        grow={[0, 1, 0]}
                        gap="10px"
                        justifyContent="space-between"
                        alignItems="center"
                        className="mb-2"
                      >
                        <div style={{ width: "38px" }}>
                          <Image
                            className="mr-2"
                            roundedCircle
                            style={{ width: "100%" }}
                            src={picture}
                          />
                        </div>
                        <Link to={`/user/${requester_id}`}>
                          <h4 className="m-0">{full_name || "fail"}</h4>
                        </Link>
                      </Flex>
                      <Controls id={request_id} isLoading={isLoading} />
                    </Flex>
                  </Alert>
                );
              }
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};
