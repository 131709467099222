import React, { useContext, useState } from "react";
import { AuthPage } from "./Pages/Login";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { RideType } from "./data/models/ride";
import styled, { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "./styles";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { RideRequest } from "./data/models/request";
import { Profile } from "./Pages/Profile";
import { User } from "./Pages/User";
import { HomePage } from "./Pages/HomePage";
import { RideRoutes } from "./Pages/Rides/RideRoutes";
import { Navigation } from "./Nav/Navigation";
import { Layout } from "./Layout/Layout";
import { ScrollArea } from "./Layout/ScrollArea";
import { Search } from "./Pages/Search";
import { Image } from "react-bootstrap";
import notFound from "./assets/img/undraw_page_not_found_re_e9o6.svg";
import { Flex } from "./Flex";

export type SearchState = {
  from: [number, number] | null;
  to: [number, number] | null;
  date: string | null;
  seats: string;
};

const initialSearchState = { from: null, to: null, date: null, seats: "0" };
type ContexType = {
  searchParams: SearchState;
  applySearchParams: (params: SearchState) => void;
  selectRide: (ride: RideType | null) => void;
  selectedRide: RideType | null;
  requests: RideRequest[];
  addRequest: (request: RideRequest) => void;
};

const SearchContext = React.createContext<ContexType>({
  searchParams: { from: null, to: null, date: null, seats: "0" },
  applySearchParams: () => {},
  selectRide: () => {},
  selectedRide: null,
  requests: [],
  addRequest: () => {},
});

export const useApiContext = () => useContext(SearchContext);

export const LogoWrapper = styled.div`
  height: 50px;
  & > img {
    height: 100%;
  }
`;

export const App = () => {
  const [searchParams, setSearchParams] =
    useState<SearchState>(initialSearchState);
  const [selectedRide, setSelectedRide] = useState<RideType | null>(null);
  const [requests, setRequests] = useState<RideRequest[]>([]);

  const selectRide = (ride: RideType | null) => {
    setSelectedRide(ride);
  };
  const applySearchParams = (params: SearchState) => {
    setSearchParams(params);
  };

  const addRequest = (request: RideRequest) => {
    setRequests((prev) => [...prev, request]);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter basename="/">
        <SearchContext.Provider
          value={{
            searchParams,
            applySearchParams,
            selectRide,
            selectedRide,
            requests,
            addRequest,
          }}
        >
          <Navigation />
          <Layout>
            <ScrollArea className="srcoll-area">
              <AnimatedRoutes />
            </ScrollArea>
          </Layout>
        </SearchContext.Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <TransitionGroup component={null}>
      <CSSTransition key={location.pathname} timeout={600} classNames="fade">
        <Routes location={location}>
          <Route path="/" element={<HomePage />} />
          <Route path="user/rides/*" element={<RideRoutes />} />
          <Route path="rides/search" element={<Search />} />
          <Route path="login" element={<AuthPage />} />
          <Route path="signup" element={<AuthPage view="sign_up" />} />
          <Route path="profile" element={<Profile />} />
          <Route path="user/:id" element={<User />} />
          <Route
            path="*"
            element={
              <Flex
                className="mt-5"
                alignItems="center"
                justifyContent="center"
              >
                <Image width={"50%"} src={notFound} />
              </Flex>
            }
          />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}
