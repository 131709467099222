export const applyStyleProp = (value: string | undefined, propName: string) => {
  return value ? `${propName}:${value};` : ''
}

export function timeStrToUtcTimestamp(timeStr: string) {
  // Split date and time strings into components
  const [hours, minutes] = timeStr.split(':').map(Number);
  // Create a date object using the date components
  // Note: The month is 0-indexed in JavaScript Date (0 for January, 1 for February, etc.)
  const localDate = new Date(Date.now());
  localDate.setHours(hours)
  const timestamp = localDate.setMinutes(minutes)
  // Convert the local date to a UTC timestamp
  return timestamp;
}

export function dateStrToUtcTimestamp(dateStr: string) {
  // Split date and time strings into components
  const [year, month, day] = dateStr.split('-').map(Number);

  // Create a date object using the date components
  // Note: The month is 0-indexed in JavaScript Date (0 for January, 1 for February, etc.)
  const localDate = new Date(year, month - 1, day);

  return localDate.getTime();
}

export const priceRegex = /^[0-9]*[.,]{0,1}[0-9]{0,2}$/
export const integerInputRegex = /^[0-9]{0,1}$/;

export function formatDate(timestamp?: number) {
  if (timestamp === undefined) {
    return `0000-00-00`
  }
  const date = new Date(timestamp);

  const year = date.getFullYear().toString();
  let month = (date.getMonth() + 1).toString(); // getMonth() returns month from 0 to 11
  let day = date.getDate().toString();

  // Pad the month and day with a leading zero if they are less than 10
  month = parseInt(month) < 10 ? `0${month}` : month;
  day = parseInt(day) < 10 ? `0${day}` : day;

  return `${year}-${month}-${day}`;
}

export function convert12HourTo24Hour(time12Hour: string) {
  // Split the time string into hours, minutes, and AM/PM parts
  const [time, period] = time12Hour.split(' ');

  // Split the hours and minutes
  let [hours, minutes] = time.split(':').map(Number);

  // Convert to 24-hour format
  if (period === 'p.m' && hours !== 12) {
    // Add 12 hours to hours if it's PM (except for 12:00 PM)
    hours += 12;
  } else if (period === 'a.m' && hours === 12) {
    // Special case: 12:00 AM becomes 00:00
    hours = 0;
  }

  // Format the hours and minutes with leading zeros
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  // Create the 24-hour time string
  const time24Hour = `${formattedHours}:${formattedMinutes}`;

  return time24Hour;
}

export function convertTimeStrToUTC(timeStr: string) {
  // ... (previous code to convert 12-hour to 24-hour)
  const [hours, minutes] = timeStr.split(':')
  // Create a new Date instance with the 24-hour time
  const date = new Date();

  date.setHours(parseInt(hours))
  date.setMinutes(parseInt(minutes))
  // Get the UTC timestamp
  return date.toString();
}

export function convertTimestampToTime(utcTimestamp: string) {
  // Create a Date instance from the UTC timestamp
  if (!utcTimestamp) return "No time"
  const date = new Date(utcTimestamp);

  return Intl.DateTimeFormat(undefined, {
    hour: "numeric",
    minute: "2-digit",
    hour12: true
  }).format(date);
}

export function calculateBoundingBox(lng: number, lat: number, distanceInKm: number) {
  // Earth’s radius, sphere
  const R = 6378.1; // kilometers

  // Convert latitude and longitude from degrees to radians
  const latRad = lat * Math.PI / 180;
  const lngRad = lng * Math.PI / 180;

  // Distance in radians
  const distanceRad = distanceInKm / R;

  // Min/max latitude in radians
  const minLat = latRad - distanceRad;
  const maxLat = latRad + distanceRad;

  // Min/max longitude in radians
  const minLng = lngRad - distanceRad / Math.cos(latRad);
  const maxLng = lngRad + distanceRad / Math.cos(latRad);

  // Convert back to degrees
  return [
    minLng * 180 / Math.PI,
    minLat * 180 / Math.PI,
    maxLng * 180 / Math.PI,
    maxLat * 180 / Math.PI,
  ];
}
