import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { Flex } from "../../Flex";
import { useGetUserRide, useDeleteRide } from "../../data/api/ride";
import { useUser } from "../../services/user";
import { JoinRequestButton } from "./JoinRequest";
import { ActionButton } from "../../ActionButton/ActionButton";
import { RideDetails } from "./RideDetails";
import { Spinner } from "../../Spinner/Spinner";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";

export function Ride() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const { data: rideDetails, isLoading: isRideLoading } = useGetUserRide({
    id,
    enabled: !!id,
    keepPreviousData: false,
  });

  const {
    mutate: deleteRide,
    isSuccess: isDeleteSucceeded,
    isLoading: isDeleting,
  } = useDeleteRide({ id });

  const { user } = useUser();

  useEffect(() => {
    isDeleteSucceeded && navigate("/user/rides", { replace: true });
  }, [isDeleteSucceeded, navigate]);

  if (isRideLoading || !rideDetails) {
    return <Spinner variant="themed" type="modal" />;
  }
  const isUserAuthor = user?.id === rideDetails?.users?.id;
  const isActionsDisabled = isDeleting;

  const Actions = () => {
    if (isUserAuthor) {
      return (
        <Flex gap="10px" alignItems="center">
          <ActionButton
            disabled={isActionsDisabled}
            size="lg"
            variant={"outline-info"}
            onClick={() => navigate("edit")}
          >
            Edit ride
          </ActionButton>
          <ActionButton
            disabled={isActionsDisabled}
            size="lg"
            variant="danger"
            onClick={() => setConfirmationVisible(true)}
          >
            Remove ride
          </ActionButton>
        </Flex>
      );
    } else {
      return <JoinRequestButton rideDetails={rideDetails} />;
    }
  };

  return (
    <>
      <RideDetails rideDetails={rideDetails} />
      <Actions />
      <Modal show={confirmationVisible}>
        <ModalHeader>
          <h5>Remove ride</h5>
        </ModalHeader>

        <ModalBody>
          <p>
            Removing rides can affect your reviews. We will notify passengers of
            the cancellation.
          </p>
          <h5>Still want to remove this ride?</h5>
        </ModalBody>
        <ModalFooter>
          <Flex gap="10px">
            <Button
              disabled={isDeleting}
              variant="danger"
              onClick={() => deleteRide({ id })}
            >
              Remove {isDeleting && <Spinner size="sm" variant="simple" />}
            </Button>
            <Button onClick={() => setConfirmationVisible(false)}>
              Cancel
            </Button>
          </Flex>
        </ModalFooter>
      </Modal>
    </>
  );
}
