import { Image } from "react-bootstrap";
import { Flex } from "../Flex";
import { useUser } from "../services/user";
import { placeholderAvatar } from "../constants";

export const ProfileThumbnail = () => {
  const { user } = useUser();
  return (
    <Flex gap="10px" alignItems="center">
      <Image
        style={{ height: "50px" }}
        src={user?.user_metadata.avatar_url || placeholderAvatar}
        alt="User Profile"
        roundedCircle
        fluid
      />
    </Flex>
  );
};
