import { Container, Row as _Row, Col, Image } from "react-bootstrap";
import CityDriver from "../../assets/img/undraw_city_driver_re_9xyv.svg";
import SearchLocation from "../../assets/img/undraw_location_search_re_ttoj.svg";
import RideRequest from "../../assets/img/undraw_order_ride_re_372k.svg";
import RideRequestApprovel from "../../assets/img/undraw_order_a_car_-3-tww.svg";
import TravelTogether from "../../assets/img/undraw_travel_together_re_kjf2.svg";
import { styled } from "styled-components";
import { PropsWithChildren } from "react";
import { ContentArea } from "../../Layout";
import { ActionButton } from "../../ActionButton";
import { useNavigate } from "react-router";

const POOLIT = "Poolit";
const SUPPORT_EMAIL = "support@poolit.ca";
const ImageContainer = styled.div`
  overflow: hidden;
  height: 200px;
  & img {
    height: 100%;
    margin: 0 auto;
    display: block;
  }
`;
const Row: React.FC<PropsWithChildren> = ({ children }) => (
  <_Row className="my-5 py-5">{children}</_Row>
);

export const HomePage = () => {
  const navigate = useNavigate();
  return (
    <ContentArea>
      <Container>
        <_Row className="my-4">
          <Image className="mb-5" src={CityDriver} />
          <Col>
            <h1>Welcome to {POOLIT} - Your Journey, Shared.</h1>
            <p>
              Ride Together, Save More. Discover a smarter way to commute with
              {POOLIT}. Connect with riders and drivers heading in the same
              direction and transform your daily travels into a shared
              adventure. Less traffic, lower costs, and a smaller carbon
              footprint – that's our promise to you.
            </p>
          </Col>
        </_Row>

        <h2 className="text-center">How It Works</h2>
        <Row>
          <Col className="px-3 mb-5 mb-md-0" md={6}>
            <ImageContainer>
              <Image src={SearchLocation} />
            </ImageContainer>
          </Col>
          <Col className="px-3 mb-5 mb-md-0" md={6}>
            <ActionButton
              onClick={() => navigate("/rides/search")}
              className="mb-3"
            >
              <h4 className="mb-0">Find Your Ride</h4>
            </ActionButton>
            <p>
              Browse through available rides that match your destination and
              schedule.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="px-3 mb-5 mb-md-0" md={6}>
            <ImageContainer>
              <Image src={RideRequest} />
            </ImageContainer>
          </Col>
          <Col className="px-3 mb-5 mb-md-0" md={6}>
            <h4>Request to Join:</h4>
            <p>Once you've found your ride, send a request to join.</p>
          </Col>
        </Row>
        <Row>
          <Col className="px-3 mb-5 mb-md-0" md={6}>
            <ImageContainer>
              <Image src={RideRequestApprovel} />
            </ImageContainer>
          </Col>
          <Col className="px-3 mb-5 mb-md-0" md={6}>
            <h4>Driver Approval:</h4>
            <p>
              The driver reviews your request. Once approved, you’re all set for
              your trip.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="px-3 mb-5 mb-md-0" md={6}>
            <ImageContainer>
              <Image src={TravelTogether} />
            </ImageContainer>
          </Col>
          <Col className="px-3 " md={6}>
            <h4>Enjoy the Ride:</h4>
            <p>
              Meet your driver at the designated pickup location and enjoy the
              journey.
            </p>
          </Col>
        </Row>

        {/* <Row className="my-4">
        <Col>
        <h2 className="text-center">Why Choose {POOLIT}?</h2>
        <p>Eco-Friendly: Reduce your carbon footprint one ride at a time.</p>
        <p>Wallet-Friendly: Save on travel costs by sharing rides.</p>
        <p>
        Community: Connect with fellow commuters, make new friends, or
        simply enjoy the journey with like-minded individuals.
        </p>
        <p>
        Safety First: Your safety is our top priority. We ensure that all
        members of the {POOLIT} community are verified and trustworthy.
        </p>
        </Col>
      </_Row> */}

        <_Row className="my-4">
          <Col>
            <h2 className="text-center">Join Our Community</h2>
            <p>
              Ready to make your daily commute more sustainable, economical, and
              fun? Download {POOLIT} today and start sharing your journey!
            </p>
          </Col>
        </_Row>

        <_Row className="my-4">
          <Col>
            <h2 className="text-center">Contact Us</h2>
            <p>
              Have questions or need support? Our team is here to help. Reach
              out to us at {SUPPORT_EMAIL}.
            </p>
          </Col>
        </_Row>
      </Container>
    </ContentArea>
  );
};
