import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "react-query";
import {
  RideType,
  RideIncoming,
  RideStop,
  SearchResultRide,
  EnrichedRide,
} from "../models/ride";
import {
  Bbox,
  createRide,
  deleteRide,
  findRides,
  readRide,
  readRides,
  updateRide,
} from "../../services/rides";
import { RideRequest } from "../models/request";
import { validateRide } from "../schemas/ride";
import { validateSearchParams } from "../schemas/search";

export type ErrorObject = {
  [key in keyof Omit<RideType, "stops">]?: string;
} & {
  stops?: { [key: number]: Record<keyof RideStop, string> };
};
export type MutationOptions = UseMutationOptions<any, ErrorObject, RideType>;
export type MutationOptionsDelete = UseMutationOptions<any, any, any>;
export type QueryOptions = UseQueryOptions<any, any, RideIncoming[]> & {
  id?: string;
};
export type SingleQueryOptions = UseQueryOptions<any, any, EnrichedRide> & {
  id?: string;
};

export type RideRequestsQueryOptions = UseQueryOptions<
  any,
  any,
  RideRequest
> & {
  rideId: string;
};

export type FindRideQueryOptions = UseMutationOptions<
  any,
  ErrorObject,
  SearchResultRide[]
>;

export const useDeleteRide = (props: MutationOptions & { id?: string }) => {
  return useMutation<any, ErrorObject, any>("delete ride", () => {
    if (props.id) {
      return deleteRide(props.id);
    }
    return Promise.reject();
  });
};
export const useUpdateRide = () => {
  return useMutation<any, ErrorObject, any & { users: any }>(
    "update ride",
    (update) => updateRide(update)
  );
};

export const useGetUserRides = (props: QueryOptions & { id?: string }) => {
  return useQuery<any, ErrorObject, RideIncoming[]>(
    "user rides",
    () => {
      if (props.id) {
        return readRides(props.id);
      }
    },
    props
  );
};

export const useGetUserRide = (props: SingleQueryOptions) => {
  return useQuery<any, any, EnrichedRide>(
    props.id + "getUserRide",
    () => {
      if (props.id) {
        return readRide(props.id);
      }
    },
    props
  );
};

export const usePublishRide = (props?: MutationOptions) => {
  return useMutation<any, ErrorObject, RideType>(
    "new ride",
    (ride) => {
      const { errors } = validateRide(ride);
      if (!errors) {
        return createRide(ride);
      } else {
        return Promise.reject(errors);
      }
    },
    props
  );
};
interface SearchParams {
  from?: Bbox;
  to?: Bbox;
  date?: string;
}
export const useFindRides = () => {
  return useMutation<SearchResultRide[], any, SearchParams>(
    "find rides",
    (params) => {
      const { errors } = validateSearchParams(params);

      if (!errors) {
        return findRides(params as Required<SearchParams>);
      } else {
        return Promise.reject(errors);
      }
    }
  );
};
