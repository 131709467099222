import styled from "styled-components";
import DatePicker from "rsuite/DatePicker";

export const DateTimePicker = styled(DatePicker)<{ isInvalid?: boolean }>`
  * {
    font-size: 1.3rem;
  }
  & input::placeholder {
    color: ${({ theme }) => theme.colors.grey};
  }
  & input:focus {
    color: ${({ theme }) => theme.colors.grey};
  }
  .rs-picker-clean {
    padding: 0;
  }
  .rs-input-group {
    &.rs-input-group.rs-input-group-inside {
      & input {
        padding-right: 10px;
      }
    }
    border: 1.5px solid
      ${(props) => (props.isInvalid ? `var(--bs-danger)` : `var(--bs-primary)`)};
    height: 100%;
    outline: none !important;
    &:hover,
    &:focus {
      outline: none;
      border: 1px solid
        ${(props) =>
          props.isInvalid
            ? props.theme.colors.danger
            : props.theme.colors.primary};
      color: ${({ theme }) => theme.colors.text.primary};
    }
    & .rs-calendar-table-cell-is-today {
      border: 1px solid var(--primary-brand-color);
    }
  }
`;
