import { Link } from "react-router-dom";
import { Flex } from "../Flex";
import { Image, Nav } from "react-bootstrap";
import { MobileOnlyBox } from "../styles";
import { useUser } from "../services/user";
import { placeholderAvatar } from "../constants";

export const ProfileNavLink = () => {
  const { user } = useUser();

  if (user) {
    return (
      <Nav.Link as={Link} eventKey={"profile"} to="profile">
        <Flex alignItems="center" justifyContent="space-between">
          <MobileOnlyBox>
            <Image
              style={{ height: "50px" }}
              src={user.user_metadata.avatar_url || placeholderAvatar}
              alt="User Profile"
              roundedCircle
              fluid
            />
            <h5>{user.user_metadata.name}</h5>
          </MobileOnlyBox>
        </Flex>
      </Nav.Link>
    );
  } else {
    return (
      <Flex gap="10px" justifyContent="space-between">
        <MobileOnlyBox>
          <Image
            style={{ height: "50px" }}
            src={placeholderAvatar}
            thumbnail
            alt="User Profile"
            roundedCircle
            fluid
          />
        </MobileOnlyBox>
      </Flex>
    );
  }
};
