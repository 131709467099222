import styled from "styled-components";
import { applyStyleProp } from "../utils/utils";

interface Props {
  gap?: string;
  grow?: number[];
  shrink?: number[];
  fillWidth?: boolean;
  alignItems?:
    | "center"
    | "start"
    | "end"
    | "flex-start"
    | "flex-end"
    | "self-start"
    | "self-end"
    | "baseline"
    | "first baseline"
    | "last baseline"
    | "safe center"
    | "unsafe center"
    | "inherit"
    | "initial"
    | "revert"
    | "revert-layer"
    | "unset";
  justifyContent?:
    | "space-between"
    | "space-around"
    | "flex-end"
    | "center"
    | "flex-start";
  direction?: "column" | "row";
}

export const Flex = styled.div<Props>`
  display: flex;
  ${(props) => (!props.fillWidth ? "" : `width: 100%;`)}
  ${(props) => applyStyleProp(props.alignItems, "align-items")}
  ${(props) => applyStyleProp(props.gap, "gap")}
  ${(props) => applyStyleProp(props.justifyContent, "justify-content")}
  ${(props) => applyStyleProp(props.direction, "flex-direction")}
  ${(props) => {
    return `
      & {
        ${props.grow
          ?.map((value, index) => {
            if (value > -1) {
              return `& > *:nth-child(${index + 1}){
                flex-grow: ${value};
              };\n`;
            } else {
              return ``;
            }
          })
          .join("")}
      }
    `;
  }}
  ${(props) => {
    return `
      & {
        ${props.shrink
          ?.map((value, index) => {
            return `& > *:nth-child(${index + 1}){
            flex-shrink: ${value};
          };\n`;
          })
          .join("")}
      }
    `;
  }}
`;
