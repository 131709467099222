import { User } from "@supabase/supabase-js";
import {
  RideType,
  RideStop,
  RideIncoming,
  EnrichedRide,
} from "../data/models/ride";
import { supabase } from "./supabase";
import { cloneDeep } from "lodash";

export const deleteRide = async (id: string) => {
  return supabase.from("rides").delete().eq("id", id);
};

export const createRide = async ({ stops, ...ride }: RideType) => {
  return supabase
    .rpc("save_ride_and_stops", {
      ride,
      stops,
    })
    .then((res) => ({ id: res.data }));
};

export const readRides = (id: User["id"]) => {
  return supabase
    .from("rides")
    .select("*, stops(*), users(picture, full_name)")
    .eq("driver_id", id)
    .then((res) => {
      const rides = res.data;
      rides?.forEach((ride) => {
        ride.stops.sort((a: RideStop, b: RideStop) =>
          a.sequence > b.sequence ? 1 : -1
        );
      });
      return rides;
    });
};

export const readRide = (id: RideIncoming["id"]) => {
  return supabase
    .from("rides")
    .select("*, stops(*), users(picture, email, id, full_name)")
    .eq("id", id)
    .single()
    .then((res) => {
      const ride = cloneDeep(res.data);
      ride.stops = ride.stops.sort((a: RideStop, b: RideStop) =>
        a.sequence > b.sequence ? 1 : -1
      );
      return ride;
    });
};

export const updateRide = async ({
  stops,
  users,
  ...ride
}: EnrichedRide & { users: any }) => {
  return supabase
    .rpc("update_ride_and_stops", {
      ride,
      stops,
    })
    .select()
    .single()
    .then((res) => ({ id: res.data }));
};
export interface Bbox {
  min_lat: number;
  min_lng: number;
  max_lat: number;
  max_lng: number;
}

export const findRides = async ({
  from,
  to,
  date,
}: {
  from: Bbox;
  to: Bbox;
  date: string;
}) => {
  return await supabase
    .rpc("find_rides", {
      frombbox: from,
      tobbox: to,
      date,
    })
    .throwOnError()
    .then((data) => data.data);
};
