import { GeoCoderDisplay } from "../Geocoder";
import { EnrichedRide, RideIncoming } from "../data/models/ride";
import { DetailsRow, Type } from "../Pages/Rides/DetailsRow";

interface Props {
  ride: EnrichedRide | RideIncoming;
  origin?: string;
  destination?: string;
  hideColumns?: { time?: boolean; icons?: boolean; places?: boolean };
}

export const StopsDisplay: React.FC<Props> = ({
  ride,
  hideColumns,
  origin,
  destination,
}) => {
  const isOrigin = (id: string, index: number) => {
    if (origin) {
      return origin === id;
    } else {
      return index === 0;
    }
  };
  const isDestination = (id: string, index: number) => {
    if (destination) {
      return destination === id;
    } else {
      return index === ride.stops.length - 1;
    }
  };

  const getStopType = (place_id: string, index: number) => {
    switch (true) {
      case isOrigin(place_id, index):
        return Type.origin;
      case isDestination(place_id, index):
        return Type.destination;
      default:
        return Type.stopover;
    }
  };

  return (
    <>
      {ride.stops.map((stop, index) => {
        if (index === 0) {
          return (
            <>
              <DetailsRow
                key={index}
                type={getStopType(stop.place_id, index)}
                place={<GeoCoderDisplay placeId={stop.place_id} />}
                time={
                  hideColumns?.time
                    ? null
                    : Intl.DateTimeFormat("en-CA", {
                        hour12: true,
                        hour: "numeric",
                        minute: "numeric",
                      }).format(new Date(ride.date_time))
                }
              />
              {!hideColumns?.icons && (
                <DetailsRow<Type.dot> key={index + "dot "} type={Type.dot} />
              )}
            </>
          );
        }
        if (index === ride.stops.length - 1) {
          return (
            <DetailsRow
              key={index}
              type={getStopType(stop.place_id, index)}
              place={<GeoCoderDisplay placeId={stop.place_id} />}
              time={
                hideColumns?.time
                  ? null
                  : Intl.DateTimeFormat("en-CA", {
                      hour12: true,
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(ride.date_time))
              }
            />
          );
        }
        return (
          <>
            <DetailsRow
              key={index}
              type={getStopType(stop.place_id, index)}
              place={<GeoCoderDisplay placeId={stop.place_id} />}
              time={null}
            />
            {!hideColumns?.icons && (
              <DetailsRow<Type.dot> key={index + "dot"} type={Type.dot} />
            )}
          </>
        );
      })}
    </>
  );
};
