import { FC } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import styled from "styled-components";

const ActionButtonWrapper = styled.div`
  width: 100%;
  & > button {
    width: 100%;
    &:disabled {
      border-color: grey;
    }
  }
`;

export const ActionButton: FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <ActionButtonWrapper>
      <Button {...rest}>{children}</Button>
    </ActionButtonWrapper>
  );
};
