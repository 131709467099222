import { Button, Container as BootstrapContaner, Image } from "react-bootstrap";
import { Flex } from "../../Flex";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { StopsDisplay } from "../../Stops/StopsDisplay";
import { RequestStatus } from "../../data/models/request";
import { EnrichedRide } from "../../data/models/ride";
import { Requests } from "../Requests/List";
import { Link } from "react-router-dom";
import { placeholderAvatar } from "../../constants";
import { useUser } from "../../services/user";
import { RequestTabs } from "./RequestTabs";
import { useRideParams } from "../../utils/hooks";

const GoBackButton = styled.div`
  color: white;
  text-align: right;
  width: 100%;
  &:hover {
    color: white;
  }
`;

const Container = styled.div`
  position: relative;

  .btn-light * {
    color: var(--primary-brand-color);
  }
`;

const Section = styled.div``;
const ProfilePhotoBox = styled.div`
  height: 80px;
  width: 80px;
  overflow: hidden;
  border-radius: 50%;

  & img {
  }
`;
// const DeletingSpinner = styled(Spinner)`
//   position: absolute;
//   top: 50%;
//   left: 50%;
// `;

// const ActionButton = styled(Button)`
//   width: 100%;
//   &:disabled {
//     border-color: grey;
//   }
// `;

const ImageWithHover = styled(Image)`
  width: 100%;
  transition: 0.3s ease;
  border: 3px solid lightgrey;
  padding: 5px;
  &:hover {
    border: 3px solid var(--primary-brand-color);
  }
`;
const TabContent = styled.div`
  height: 250px;
  overflow: auto;
`;

interface Props {
  rideDetails: EnrichedRide;
}

export const RideDetails: React.FC<Props> = ({ rideDetails }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { endStop, startStop } = useRideParams();
  if (!rideDetails) {
    return null;
  }
  const isAuthor = rideDetails.driver_id === user?.id;
  return (
    <Container>
      <GoBackButton>
        <Button onClick={() => navigate(-1)} variant={"link"}>
          <strong>Go back</strong>
        </Button>
      </GoBackButton>
      <Section className="p-4 mb-4">
        <h4>
          <strong>Driver</strong>
        </h4>

        <Flex alignItems="center" gap="16px">
          <ProfilePhotoBox>
            <Link to={`/user/${rideDetails.users.id}`}>
              <ImageWithHover
                roundedCircle
                alt="user profile"
                src={rideDetails.users?.picture || placeholderAvatar}
              />
            </Link>
          </ProfilePhotoBox>
          <h4>{rideDetails.users?.full_name}</h4>
        </Flex>
      </Section>
      <Section className="p-4 mb-4">
        <Section className="mb-5">
          <Flex justifyContent="space-between">
            <h4>
              <strong>
                {Intl.DateTimeFormat("en-CA", {
                  weekday: "short",
                  day: "2-digit",
                  month: "short",
                }).format(new Date(rideDetails.date_time))}
              </strong>
            </h4>
            <h4>
              <strong>
                {Intl.DateTimeFormat("en-CA", {
                  timeStyle: "short",
                }).format(new Date(rideDetails.date_time))}
              </strong>
            </h4>
          </Flex>
        </Section>
        <BootstrapContaner>
          <StopsDisplay
            origin={
              rideDetails.stops.find((stop) => stop.id.toString() === startStop)
                ?.place_id
            }
            destination={
              rideDetails.stops.find((stop) => stop.id.toString() === endStop)
                ?.place_id
            }
            hideColumns={{ time: true }}
            ride={rideDetails}
          />
        </BootstrapContaner>
      </Section>
      <Section>
        <h5>Passengers</h5>
        {isAuthor ? (
          <RequestTabs />
        ) : (
          <Requests status={RequestStatus.Approved} />
        )}
        {/* <Tabs
          defaultActiveKey="approved"
          id="uncontrolled-tab-example"
          as={"h4"}
          className="mb-3"
        >
          <Tab eventKey="approved" title="Approved">
            <TabContent>
              <Requests status={RequestStatus.Approved} />
            </TabContent>
          </Tab>
          <Tab eventKey="pending" title="Pending">
            <TabContent>
              <Requests status={RequestStatus.Pending} />
            </TabContent>
          </Tab>
         
        </Tabs> */}
      </Section>
      <Section className="p-4 mb-4">
        <Flex justifyContent="space-between">
          <h4>Total price</h4>
          <h4>{rideDetails.price_per_seat} CAD</h4>
        </Flex>
      </Section>
    </Container>
  );
};
