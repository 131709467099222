import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  readUser,
  resendConfirmationEmail,
  signInWithGoogle,
  signOut,
  updateUser,
} from "../../services/user";
import { User } from "../models/user";

export const useSignUserInWithGoogle = () => {
  return useQuery("signIn", signInWithGoogle);
};
export const useSignOut = () => {
  return useMutation("signOut", signOut);
};

export const useGetUser = (
  props: UseQueryOptions & { id: string | undefined }
) => {
  return useQuery<any, any, User>("getUser", () => readUser(props.id));
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation("updateUser", updateUser, {
    onSuccess: () => queryClient.resetQueries("updateUser"),
  });
};
export const useResendConfirmation = () => {
  return useMutation("resendConfirmation", resendConfirmationEmail);
};
