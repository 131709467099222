import { supabase } from "./supabase";
import { NewRequest, RequestStatus, RideRequest } from "../data/models/request";

export const createRequest = async (request: NewRequest) => {
  return await supabase
    .from("requests")
    .insert(request)
    .single()
    .throwOnError();
};

export const updateRequest = async (request: Partial<RideRequest>) => {
  return supabase
    .from("requests")
    .update(request)
    .eq("id", request.id)
    .select()
    .single()
    .then((data) => {
      if (data.error) {
        throw data.error;
      }
      return data;
    });
};
export interface ReadRequestsArgs {
  ride_id: string;
  requester_id: string | undefined;
  stop_id_from: string | null;
  stop_id_to: string | null;
}
type ReadRequest = (arg: ReadRequestsArgs) => Promise<any>;

export const readRequest: ReadRequest = async ({
  ride_id,
  requester_id,
  stop_id_from,
  stop_id_to,
}) => {
  return supabase
    .from("requests")
    .select()
    .eq("ride_id", ride_id)
    .eq("stop_id_to", stop_id_to)
    .eq("stop_id_from", stop_id_from)
    .eq("requester_id", requester_id)
    .throwOnError()
    .then(({ data }) => data);
};
export const readRequests = async (ride_id: string, status: RequestStatus) => {
  return supabase.rpc("get_ride_requests", { incoming_id: ride_id });
};
