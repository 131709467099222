import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { calculateBoundingBox } from "../utils/utils";
import { Bbox } from "../services/rides";
import { Form, ListGroup } from "react-bootstrap";
import { useGetPlaces, useSearchPlaces } from "../data/api/places";
import { useDebouncedValue, useOutsideClick } from "../utils/hooks";

export interface Props {
  onSelect?: (arg: {
    id: MapboxGeocoder.Result["id"];
    lat: number;
    lng: number;
    name: string;
    bbox?: Bbox;
  }) => void;
  onClear?: () => void;
  isInvalid?: boolean;
  reverseSearchValue?: string;
  inputProps?: { placeholder?: string };
  iconPath?: string;
}

const List = styled(ListGroup)`
  background: ${({ theme }) => theme.colors.white};
  min-width: 320px;
  width: 100%;
  z-index: 1;
  position: absolute;
  padding: 0;

  & > .list-group-item {
    p {
      color: ${({ theme }) => theme.colors.grey};
    }
    cursor: pointer;
    padding-left: 15px;
    background-color: ${({ theme }) => theme.colors.white};
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    &:last-child {
      border: none;
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;
const Wrapper = styled.div<{ isInvalid?: boolean }>`
  & .form-control {
    font-size: 1.3rem;
    padding-left: 15px;
    &::placeholder {
      color: ${({ theme }) => theme.colors.grey};
    }
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid
      ${(props) =>
        props.isInvalid
          ? props.theme.colors.danger
          : props.theme.colors.primary};
  }
  position: relative;
`;
export const GeoCoderInput: React.FC<Props> = ({
  onSelect,
  onClear,
  isInvalid,
  reverseSearchValue,
  inputProps,
}) => {
  const [value, setValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>(
    reverseSearchValue || ""
  );
  const debouncedValue = useDebouncedValue(searchValue);

  const { data, mutate, reset } = useSearchPlaces({});

  const { data: reserveResult } = useGetPlaces({
    placeId: reverseSearchValue,
    enabled: !!reverseSearchValue,
  });

  useEffect(() => {
    if (reserveResult) {
      setValue(reserveResult.place_name);
      handleSelect(reserveResult);
    }
  }, [reserveResult]);

  const handleSelect = (feature: MapboxGeocoder.Result) => {
    const [lng, lat] = feature.center;
    const [min_lng, min_lat, max_lng, max_lat] =
      feature.bbox || calculateBoundingBox(lng, lat, 1);
    setValue(feature.place_name);
    reset();
    onSelect &&
      onSelect({
        id: feature.id,
        lat,
        lng,
        name: feature.text,
        bbox: { max_lat, max_lng, min_lat, min_lng },
      });
  };

  useEffect(() => {
    debouncedValue && !reverseSearchValue && mutate({ value: debouncedValue });
  }, [debouncedValue, reverseSearchValue]);

  useEffect(() => {
    if (!value && !reverseSearchValue) {
      reset();
    }
  }, [value, reverseSearchValue]);

  const ref = useRef(null);

  useOutsideClick(ref, () => reset());

  return (
    <Wrapper ref={ref} isInvalid={isInvalid}>
      <Form.Control
        onChange={({ target }) => {
          setValue(target.value);
          if (target.value.length > 2) {
            setSearchValue(value);
          }

          if (!target.value) {
            reset();
          }
          onClear?.();
        }}
        value={value}
        type="text"
        placeholder={inputProps?.placeholder || ""}
      />
      <List className="mt-2 box-shadow">
        {data?.features.map((feature) => {
          return (
            <ListGroup.Item
              key={feature.id}
              onClick={() => handleSelect(feature)}
            >
              <h5>{feature.text}</h5>
              <p className="m-0">{feature.place_name}</p>
            </ListGroup.Item>
          );
        })}
      </List>
    </Wrapper>
  );
};
