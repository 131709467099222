import { useLocation, Routes, Route, useNavigate } from "react-router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { usePublishRide } from "../../data/api/ride";
import { RideList } from "./Rides";
import { EditRide } from "./EditRide";
import { Ride } from "./Ride";
import { ContentArea } from "../../Layout";
import { RideForm } from "../../Forms/RideForms";
import { useEffect } from "react";

export const RideRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    mutate: publishRide,
    isLoading,
    isSuccess,
    error,
    reset,
    data,
  } = usePublishRide();

  useEffect(() => {
    if (isSuccess) {
      navigate(`/user/rides/${data.id}`);
      reset();
    }
  }, [isSuccess, data]);
  return (
    <ContentArea>
      <TransitionGroup>
        <CSSTransition key={location.key} timeout={600} classNames="fade">
          <Routes location={location}>
            <Route path=":id/edit" element={<EditRide />} />
            <Route path=":id" element={<Ride />} />
            <Route
              path="/new"
              element={
                <RideForm
                  initialErrors={error}
                  onSave={(ride) => publishRide(ride)}
                  savingDetails={{
                    isSaved: isSuccess,
                    isSaving: isLoading,
                  }}
                  initialRide={{
                    stops: [{}, {}],
                  }}
                />
              }
            />
            <Route path="/" element={<RideList />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </ContentArea>
  );
};
