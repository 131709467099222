import { cloneDeep } from "lodash";
import { useState, useEffect } from "react";
import { Container as BootstrapContaner, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router";

import styled from "styled-components";
import { DateTimePicker } from "../DateTimePicker/DateTimePicker";
import { ErrorText } from "../ErrorText";
import { Flex } from "../Flex";
import { NumberField } from "../NumberField";
import { StopsDisplayEdit } from "../Stops";
import { placeholderAvatar } from "../constants";
import { isNewStop, StopUpdateAction } from "../data/models/ride";
import { Stop } from "../data/models/stop";
import { useUser } from "../services/user";
import { integerInputRegex, priceRegex } from "../utils/utils";
import { ActionButton } from "../ActionButton";
import { Spinner } from "../Spinner/Spinner";
import seatImg from "../assets/img/car-seat.png";

import "rsuite/DatePicker/styles/index.css";

const Container = styled.div`
  position: relative;
  .btn-light * {
    color: var(--primary-brand-color);
  }
`;

const Section = styled.div``;
const ProfilePhotoBox = styled.div`
  height: 50px;
  width: 50px;
  overflow: hidden;
  border-radius: 50%;

  & img {
    height: 50px;
    width: 50px;
  }
`;

const InputGroupWrapper = styled.div<{ isInvalid?: boolean }>`
  .input-group-text {
    min-width: 60px;
    display: flex;
    justify-content: center;
    font-weight: bold;

    border: 1px solid
      ${(props) =>
        props.isInvalid
          ? props.theme.colors.primary.error
          : props.theme.colors.primary.brand};
    background-color: var(--primary-brand-color-opacity-low);
  }
  input {
    background: white;
    border: 1px solid
      ${(props) =>
        props.isInvalid
          ? props.theme.colors.primary.error
          : props.theme.colors.primary.brand};
  }
`;

interface Props {
  initialRide: any;
  onSave: (ride: any) => void;
  initialErrors?: any;
  savingDetails: {
    isSaving: boolean;
    isSaved: boolean;
  };
}

export const RideForm: React.FC<Props> = ({
  initialRide,
  onSave,
  savingDetails,
  initialErrors,
}) => {
  const navigate = useNavigate();
  const { user: driver } = useUser();
  const [rideDetails, setRideDetails] = useState(initialRide);
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    setErrors(initialErrors || {});
  }, [initialErrors]);

  useEffect(() => {
    setRideDetails((rideDetails) => ({
      ...rideDetails,
      driver_id: driver?.id,
    }));
  }, [driver]);

  const updateStop = (
    index: number,
    update: {
      lat: Stop["lat"];
      lng: Stop["lng"];
      place_id: Stop["place_id"];
    }
  ) => {
    setErrors((errors) => {
      const updatedErrors = { ...errors };
      if (updatedErrors.stops?.[index]) {
        updatedErrors.stops[index] = null;
      }
      return updatedErrors;
    });
    setRideDetails((details) => {
      const detailsClone = cloneDeep(details);
      if (detailsClone?.stops[index]) {
        detailsClone.stops[index].lat = update.lat;
        detailsClone.stops[index].lng = update.lng;
        detailsClone.stops[index].place_id = update.place_id;
      }
      if (!isNewStop(detailsClone.stops[index])) {
        detailsClone.stops[index].action = StopUpdateAction.updated;
      } else {
        detailsClone.stops[index].action = StopUpdateAction.added;
      }
      return detailsClone;
    });
  };

  if (!initialRide) {
    return null;
  }

  const handleEdit = () => {
    if (rideDetails) {
      onSave({
        ...rideDetails,
        stops: rideDetails.stops.map((stop, index) => ({
          ...stop,
          sequence: index,
        })),
      });
    }
  };

  const Actions = () => {
    return (
      <Flex gap="10px">
        <ActionButton
          disabled={savingDetails.isSaving}
          size="lg"
          color="success"
          variant="outline-success"
          onClick={handleEdit}
        >
          Save
        </ActionButton>
        <ActionButton
          disabled={savingDetails.isSaving}
          size="lg"
          variant="danger"
          onClick={() => navigate(-1)}
        >
          Cancel
        </ActionButton>
      </Flex>
    );
  };

  if (!rideDetails) {
    return null;
  }

  return (
    <>
      {savingDetails.isSaving && <Spinner type="modal" />}
      <Container>
        <Section className="p-4 mb-4">
          <h4>Driver</h4>
          <Flex alignItems="center" gap="16px">
            <ProfilePhotoBox>
              <img
                src={driver?.user_metadata.avatar_url || placeholderAvatar}
              />
            </ProfilePhotoBox>
            <h4>
              {driver?.user_metadata.full_name || driver?.user_metadata.name}
            </h4>
          </Flex>
        </Section>
        <Section className="p-4 mb-5 d-inline-block">
          <h4 className="mb-4">Date</h4>
          <DateTimePicker
            isInvalid={!!errors?.date_time}
            oneTap
            classPrefix="poolit"
            onSelect={(date) => {
              setErrors((errors) => ({ ...errors, date_time: null }));
              setRideDetails((details) => {
                const cloneDetails = cloneDeep(details);
                cloneDetails.date_time = date.toISOString();
                return cloneDetails;
              });
            }}
            format="MM/dd/yyyy hh:mm aa"
            value={new Date(rideDetails.date_time)}
            showMeridian
          />
          <ErrorText className="my-2">
            {errors?.date_time && "Select a location "}
          </ErrorText>
        </Section>
        <Section className=" p-4 mb-5">
          <h4 className="mb-4">Itenary</h4>
          <BootstrapContaner>
            <StopsDisplayEdit
              errors={errors}
              ride={rideDetails}
              onPlaceClear={(index: number) => {
                setRideDetails((details) => {
                  const cloneDetails = cloneDeep(details);
                  if (cloneDetails) {
                    cloneDetails.stops[index].place_id = undefined;
                  }
                  return cloneDetails;
                });
              }}
              onPlaceChange={updateStop}
              onAddStop={() => {
                setRideDetails((details) => {
                  const cloneDetails = cloneDeep(details);
                  cloneDetails &&
                    cloneDetails.stops.splice(
                      cloneDetails.stops.length - 1,
                      0,
                      {
                        action: StopUpdateAction.added,
                      }
                    );
                  setErrors((errors) => {
                    const updatedErrors = { ...errors };
                    if (updatedErrors.stops) {
                      updatedErrors.stops[cloneDetails.stops.length - 1] =
                        updatedErrors.stops[cloneDetails.stops.length - 2];
                      updatedErrors.stops[cloneDetails.stops.length - 2] = null;
                    }

                    return updatedErrors;
                  });
                  return cloneDetails;
                });
              }}
              onRemoveStop={(index: number) => {
                setRideDetails((details) => {
                  const cloneDetails = cloneDeep(details);
                  const stop = cloneDetails?.stops[index];
                  if (cloneDetails && stop) {
                    if (!isNewStop(stop)) {
                      cloneDetails.stops[index] = {
                        ...cloneDetails.stops[index],
                        action: StopUpdateAction.removed,
                      };
                    } else {
                      cloneDetails.stops = cloneDetails.stops.filter(
                        (_value, currentIndex) => currentIndex !== index
                      );
                    }
                  }
                  return cloneDetails;
                });
              }}
            />
          </BootstrapContaner>
        </Section>
        <Section className="p-4 mb-5">
          <Flex grow={[3, 1]} justifyContent="space-between">
            <h4 className="w-100">Available seats</h4>
            <div>
              <InputGroupWrapper isInvalid={!!errors?.total_seats}>
                <InputGroup>
                  <NumberField
                    initialValue={rideDetails.total_seats?.toString() || ""}
                    isInvalid={!!errors?.total_seats}
                    placeholder="0"
                    handleChange={({ value }) => {
                      setRideDetails((details) => {
                        return { ...details, total_seats: value };
                      });
                    }}
                    regExp={integerInputRegex}
                  />
                  <InputGroup.Text>
                    <img src={seatImg} />
                  </InputGroup.Text>
                </InputGroup>
              </InputGroupWrapper>
              {errors?.price_per_seat && (
                <ErrorText className="m-2">Specify price</ErrorText>
              )}
            </div>
          </Flex>
        </Section>
        <Section className="p-4 mb-4">
          <Flex grow={[3, 1]} justifyContent="space-between">
            <h4 className="w-100">Total price</h4>
            <div>
              <InputGroupWrapper isInvalid={!!errors?.price_per_seat}>
                <InputGroup>
                  <NumberField
                    isInvalid={!!errors?.price_per_seat}
                    initialValue={rideDetails?.price_per_seat?.toString() || ""}
                    placeholder="2.50"
                    handleChange={({ value }) => {
                      setRideDetails((details) => {
                        const cloneDetails = cloneDeep(details);
                        cloneDetails &&
                          (cloneDetails.price_per_seat =
                            value || cloneDetails.price_per_seat);
                        return cloneDetails;
                      });
                    }}
                    regExp={priceRegex}
                  />
                  <InputGroup.Text>CAD</InputGroup.Text>
                </InputGroup>
              </InputGroupWrapper>
              {errors?.price_per_seat && (
                <ErrorText className="m-2">Specify price</ErrorText>
              )}
            </div>
          </Flex>
        </Section>
        <Section className="p-4">
          <Actions />
        </Section>
      </Container>
    </>
  );
};
