import { PropsWithChildren } from "react";
import { ContentAreaScroll } from "./ContentAreaScroll";
interface Props extends PropsWithChildren {
  size?: "lg";
}
export const ContentArea: React.FC<Props> = ({ children, size }) => {
  return (
    <ContentAreaScroll size={size}>
      <div>{children}</div>
    </ContentAreaScroll>
  );
};
