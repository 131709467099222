import { Request } from "./Request";
import { RequestStatus } from "../../data/models/request";
import { useGetRideRequests } from "../../data/api/request";
import { useParams } from "react-router";
import { Accordion } from "react-bootstrap";
import { createRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Spinner } from "../../Spinner/Spinner";

export const Requests = ({ status }: { status: RequestStatus }) => {
  const { id } = useParams();
  const { data: requests, isLoading } = useGetRideRequests({
    ride_id: id,
    status,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Accordion>
      <TransitionGroup>
        {requests?.data
          .filter((request) => request.status === status)
          .map((request) => ({ ...request, ref: createRef<HTMLDivElement>() }))
          .map((request, index) => (
            <CSSTransition
              addEndListener={(done) => done()}
              key={request.id}
              nodeRef={request.ref}
            >
              <div key={request.id} ref={request.ref}>
                <Request eventIndex={index} request={request} />
              </div>
            </CSSTransition>
          ))}
      </TransitionGroup>
    </Accordion>
  );
};
