import { useEffect, useState } from "react";
import { supabase } from "./supabase";
import { Session, User } from "@supabase/supabase-js";

export const readUser = async (id: string | undefined) => {
  if (!id) {
    return;
  }
  return supabase
    .from("users")
    .select()
    .eq("id", id)
    .single()
    .throwOnError()
    .then(({ data }) => data);
};
export const updateUser = async (user_meta: User["user_metadata"]) => {
  return await supabase.auth.updateUser({
    data: user_meta,
  });
};

export const resendConfirmationEmail = async (email: string) => {
  return await supabase.auth
    .resend({
      type: "signup",
      email,
      options: {
        emailRedirectTo: "poolit.ca/profile",
      },
    })
    .then((data) => data.data);
};
export const signInWithGoogle = async () =>
  await supabase.auth.signInWithOAuth({
    provider: "google",
  });

export const signOut = async () => await supabase.auth.signOut();

export const useUser = () => {
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
    setLoading(false);
    return () => subscription.unsubscribe();
  }, []);

  return {
    user: session?.user,
    isLoading: loading,
    actionNeeded:
      !session?.user.user_metadata.name || !session.user.confirmed_at,
  };
};
