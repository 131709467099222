import { useState, useEffect } from "react";
import { Modal, Alert, Image, Spinner } from "react-bootstrap";
import { useLocation } from "react-router";
import { useSearchParams, Link } from "react-router-dom";
import { Flex } from "../../Flex";
import { RedirectModal } from "../../RedirectModal";
import { useCreateRequest, useGetRequest } from "../../data/api/request";
import { RequestStatus } from "../../data/models/request";
import { EnrichedRide } from "../../data/models/ride";
import { useUser } from "../../services/user";
import { ActionButton } from "../../ActionButton/ActionButton";
import { GeoCoderDisplay } from "../../Geocoder";

export interface Props {
  rideDetails: EnrichedRide;
}

export const JoinRequestButton: React.FC<Props> = ({ rideDetails }) => {
  const [searchParams] = useSearchParams();
  const fromId = searchParams.get("startStop");
  const toId = searchParams.get("endStop");
  const { user, isLoading, actionNeeded } = useUser();
  const [showModal, setShowModal] = useState(false);
  const {
    mutate: createRequest,
    isLoading: isCreatingRequest,
    isSuccess: isRequestCreated,
    reset,
  } = useCreateRequest();

  const {
    data: request,
    isFetching,
    refetch,
  } = useGetRequest({
    ride_id: rideDetails.id,
    requester_id: user?.id,
    stop_id_from: searchParams.get("startStop"),
    stop_id_to: searchParams.get("endStop"),
    enabled: !!user,
  });

  const hasRequested = request?.length > 0 && !isFetching;

  const notAuthenticated = !user && !isLoading;

  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const location = useLocation();
  const handleRequest = () => {
    if (!user?.email) {
      return setShowModal(true);
    }

    if (!toId || !fromId || !rideDetails?.id) {
      return;
    }
    createRequest({
      requester_email: user.email,
      requester_id: user.id,
      ride_id: rideDetails.id,
      status: RequestStatus.Pending,
      stop_id_from: fromId,
      stop_id_to: toId,
    });
  };

  useEffect(() => {
    if (isRequestCreated) {
      reset();
      refetch();
      setTimeout(() => setShowModal(false), 3000);
    }
  }, [isRequestCreated]);

  const fromStop = rideDetails.stops.find(
    (stop) => stop.id.toString() === fromId
  );
  const toStop = rideDetails.stops.find((stop) => stop.id.toString() === toId);
  return (
    <>
      {!showRedirectModal && (
        <Modal show={showModal}>
          <Modal.Header closeButton onHide={() => setShowModal(false)}>
            <Modal.Title>
              <Flex gap="10px">
                <GeoCoderDisplay placeId={fromStop?.place_id} />
                <h5> to </h5>
                <GeoCoderDisplay placeId={toStop?.place_id} />
              </Flex>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {hasRequested && (
              <h5>
                Your request to join the ride is{" "}
                {request?.[0].status === RequestStatus.Approved
                  ? "approved by"
                  : "sent to"}{" "}
                <Image
                  width={"30px"}
                  roundedCircle
                  src={rideDetails.users.picture}
                />{" "}
                <Link to={`/user/${rideDetails.users.id}`}>
                  {rideDetails.users.full_name}
                </Link>
              </h5>
            )}
            {!hasRequested && !isRequestCreated && !isFetching && (
              <>
                <Flex className="mb-3" justifyContent="space-between">
                  <h4>Driver</h4>
                  <Flex gap="10px">
                    <Image
                      height={"30px"}
                      roundedCircle
                      src={rideDetails.users.picture}
                    />
                    <Link to={`/user/${rideDetails.users.id}`}>
                      <h4>{rideDetails.users.full_name}</h4>
                    </Link>
                  </Flex>
                </Flex>
                <Flex justifyContent="space-between">
                  <h4>Price</h4> <h3>{rideDetails.price_per_seat} CAD</h3>
                </Flex>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ActionButton
              variant="outline-success"
              disabled={
                isCreatingRequest ||
                isRequestCreated ||
                hasRequested ||
                isFetching
              }
              onClick={() => handleRequest()}
            >
              <Flex gap="10px" alignItems="center" justifyContent="center">
                <span>Join the trip</span>{" "}
                {isCreatingRequest && <Spinner size="sm" />}
              </Flex>
            </ActionButton>
          </Modal.Footer>
        </Modal>
      )}
      {showRedirectModal && notAuthenticated && (
        <RedirectModal
          returnPath={
            window.location.origin + location.pathname + location.search
          }
          navigateTo="/login"
          show={showRedirectModal}
          closeModal={() => setShowRedirectModal(false)}
          content="You need to be logged in"
        />
      )}
      {showRedirectModal && actionNeeded && (
        <RedirectModal
          returnPath={
            window.location.origin + location.pathname + location.search
          }
          navigateTo="/profile"
          show={showRedirectModal}
          closeModal={() => setShowRedirectModal(false)}
          content="You need to update your profile info"
        />
      )}
      {hasRequested && (
        <Alert>
          <Alert.Heading>
            Your request to join the ride is{" "}
            {request[0].status === RequestStatus.Approved
              ? "approved by"
              : "sent to"}{" "}
            <Link to={`/user/${rideDetails.users.id}`}>
              {rideDetails.users.full_name}
            </Link>
          </Alert.Heading>
        </Alert>
      )}
      <Flex justifyContent="center">{isFetching && <Spinner />}</Flex>
      {!hasRequested && !isFetching && (
        // @ts-ignore
        <ActionButton
          onClick={() => {
            if (notAuthenticated || actionNeeded) {
              return setShowRedirectModal(true);
            }

            return setShowModal(true);
          }}
          size="lg"
          variant="outline-success"
        >
          Join the trip
        </ActionButton>
      )}
    </>
  );
};
