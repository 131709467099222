import styled from "styled-components";
import { Flex } from "../../Flex";
import { SearchResultRide } from "../../data/models/ride";
import { Image } from "react-bootstrap";
import { GeoCoderDisplay } from "../../Geocoder";

const RideCard = styled.div`
  border: 1px solid;
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: var(--bs-box-shadow-sm);
  }
  .seat-avail {
    font-size: 24px;
  }
  .seat-no-avail {
    font-size: 24px;
  }
  cursor: pointer;
  transition: all 0.4s ease;
  border-radius: 5px;

  * {
    text-decoration: none;
    color: var(--bs-body-color);
  }
`;

const RideHeader = styled(Flex)`
  // background: var(--primary-brand-color-opacity-low);
  & * {
    color: darkslategrey;
  }
`;

interface Props {
  ride: SearchResultRide;
}

export const SearchRide: React.FC<Props> = ({ ride }) => {
  if (!ride) {
    return null;
  }
  const availability = ride.avail_seats || 0;
  const takenSeats = ride.total_seats - availability;
  const date = new Date(ride.date_time);

  return (
    <RideCard className="mb-3">
      <RideHeader
        gap="20px"
        className="p-4 pb-0 text-white"
        justifyContent="space-between"
      >
        <Flex gap="20px">
          <GeoCoderDisplay placeId={ride.stop_from_place_id} />
          <GeoCoderDisplay placeId={ride.stop_to_place_id} />
        </Flex>
        <h5 className="mb-3 text-center">
          {Intl.DateTimeFormat("en-CA", {
            weekday: "short",
            day: "2-digit",
            month: "short",
          }).format(date)}
        </h5>
      </RideHeader>

      <Flex
        className="w-100 p-4"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex>
          {Array(availability)
            .fill(null)
            .map(() => {
              return <i className="bi bi-person seat-avail"></i>;
            })}
          {Array(takenSeats)
            .fill(null)
            .map(() => {
              return <i className="bi bi-person-fill seat-no-avail"></i>;
            })}
        </Flex>
        <Flex direction="column">
          <Flex gap="5px" alignItems="center">
            <div style={{ width: "30px" }}>
              <Image
                className="mr-2"
                roundedCircle
                style={{ width: "100%" }}
                src={ride?.driver_avatar}
              />
            </div>
            <h6 className="m-0">{ride?.driver_name || "fail"}</h6>
          </Flex>
          <p className="m-0 tx-6 text-nowrap text-end">No rating</p>
        </Flex>
      </Flex>
    </RideCard>
  );
};
