import styled, {
  createGlobalStyle,
  css,
  Interpolation,
  DefaultTheme,
} from "styled-components";
import "./styles.scss";

// Define breakpoints for different devices

const sizes = {
  base: "0px",
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopM: "1040px",
  laptopL: "1440px",
  desktop: "2560px",
};
type SizeLabel = keyof typeof sizes;

type MediaObj = {
  [key in SizeLabel]: (
    ...args: TemplateStringsArray[]
  ) => Interpolation<DefaultTheme>[];
};

// Iterate through the sizes and create a media template
const minMedia = (Object.keys(sizes) as SizeLabel[]).reduce<Partial<MediaObj>>(
  (acc, label) => {
    acc[label] = (
      first: TemplateStringsArray,
      ...interpolations: Interpolation<DefaultTheme>[]
    ) => css`
      @media (min-width: ${sizes[label]}) {
        ${css(first, ...interpolations)};
      }
    `;
    return acc;
  },
  {}
) as MediaObj;

const maxMedia = (Object.keys(sizes) as SizeLabel[]).reduce<Partial<MediaObj>>(
  (acc, label) => {
    acc[label] = (
      first: TemplateStringsArray,
      ...interpolations: Interpolation<DefaultTheme>[]
    ) => css`
      @media (max-width: ${sizes[label]}) {
        ${css(first, ...interpolations)};
      }
    `;
    return acc;
  },
  {}
) as MediaObj;

// Exporting the theme with media queries
export const theme = {
  minMedia, // Now you can use this in other styled components
  maxMedia, // Now you can use this in other styled components
  colors: {
    primary: "#78c2ad",
    secondary: "#79c3ae1a",
    danger: "#f87c53",
    white: "white",
    grey: "#949494bf",
    text: {
      primary: "#38584f",
    },
  },
};

// Example of using media queries in a styled-component

export const GlobalStyle = createGlobalStyle`
  ${(props) => {
    return `:root {
      --bs-blue: ${props.theme.colors.primary};
      --bs-primary: ${props.theme.colors.primary};
      --rs-bg-active: ${props.theme.colors.primary};
      --rs-primary-500: ${props.theme.colors.primary};
      --rs-primary-700: ${props.theme.colors.primary};
      --rs-focus-input-border: ${props.theme.colors.primary};
    }`;
  }}
  body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    overflow: hidden;
  };
  .fade-enter {
    position: relative;
    opacity: 0;
    right: -5%;
    z-index: 1;
    top: 0;
  };
  .fade-enter-active {
    position: relative;
    opacity: 1;
    top: 0;
    right: 0%;
    transition-property: opacity, right;
    transition-delay: 0.3s , 0.3s;
    transition-timing-function: cubic-bezier(.34,1.03,.96,.98);
    transition-duration: 0.3s;
  };
  .fade-exit {
    max-height:100%;
    box-sizing: border-box;
    opacity: 0.5;
    right: 0%;
    z-index: 0;
    width:calc(100% - 15px);
    // top: 15px;
    transition-property: opacity, right, position;
    transition-delay: 0s , 0.1s;
    transition-timing-function: cubic-bezier(.34,1.03,.96,.98);
    transition-duration: 0.3s;
  };
  .fade-exit-active {
    max-height:100%;
    box-sizing: border-box;
    opacity: 0;
    right: 110%;
    width:calc(100% - 15px);
    // top:15px;
    transition-property: opacity, right, position;
    transition-delay: 0s , 0.1s;
    transition-timing-function: cubic-bezier(.34,1.03,.96,.98);
    transition-duration: 0.3s;
  }

  /* Customizing the scrollbar track (background) */
  *::-webkit-scrollbar-track {
    background: #f0f0f0; /* Color of the track */
  }
  /* Customizing the scrollbar handle */
  *::-webkit-scrollbar-thumb {
    background: var(--primary-brand-color); /* Color of the thumb */
    border-radius: 10px; /* Rounded corners of the thumb */
  }
  /* Customizing the scrollbar width and height */
  *::-webkit-scrollbar {
    width: 6px; /* Width of the vertical scrollbar */
    height: 6px; /* Height of the horizontal scrollbar */
  }
  /* Optionally, style the scrollbar corner */
  *::-webkit-scrollbar-corner {
    background: #f0f0f0; /* Color of the corner */
  }
  *::-webkit-scrollbar {
        width: 0px; /* Width of the vertical scrollbar */
    height: 0px; /* Height of the horizontal scrollbar */
  };
  ${({ theme }) => theme.maxMedia.tablet`
    *::-webkit-scrollbar {
        width: 0px; /* Width of the vertical scrollbar */
    height: 0px; /* Height of the horizontal scrollbar */
  };
`}
  .profile-thumbnail {
    position: relative;
    right: 0%;
    transition: all 0.3s cubic-bezier(.34,1.03,.96,.98);
    width: 110%;
    cursor: pointer;
    z-index: 1000;  
  }
  .peak {
    right: 20%;
  }
  .box-shadow{
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 12px rgba(0, 0, 0, 0.05);
  }
  a {
    color: var(--primary-brand-color);
  }
`;

export const MobileOnlyBox = styled.div`
  ${theme.minMedia.base`
    display: block;
  `}
  ${theme.minMedia.laptop`
    display: none;
  `}
`;

export const LaptopOnlyBox = styled.div`
  ${theme.minMedia.base`
  display: none !important;
`}
  ${theme.minMedia.laptop`
  display: block !important;
`}
`;
